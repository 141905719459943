import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';

import {
  BeziehungInfo,
  GeschlechtInfo,
  TabelleVervielfaeltigerQuelleTyp,
  Rechner,
} from 'niessbrauch-rechner';

interface Ergebnis {
  steuerfreierBetrag: number;
  schenkungssteuerBetrag: number;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  // Variablen für die Hilfeanzeige
  checkedAlter = false;
  checkedGeschlecht = false;
  checkedBeziehung = false;
  checkedZinssatz = false;

  // Variable für den "Berechnen"-Button
  rechnerInitial = true;

  // Variablen für den Disclaimer
  disclaimerPruefer =
    'FINTEGRA GmbH Steuerberatungsgesellschaft Wirtschaftsprüfungsgesellschaft';
  disclaimerRechtsstand = '01.01.';

  // Rechner initialisieren
  rechner: Rechner = new Rechner();

  // Aus dem Rechner Werte für Anzeige in den Forms ermitteln
  gueltigkeit: number = this.rechner.ermittleVerwendetesJahr();
  gueltigkeitZusatzdaten: TabelleVervielfaeltigerQuelleTyp = this.rechner.ermittleVerwendetesJahrZusatzdaten(
    this.gueltigkeit
  );
  geschlechter: GeschlechtInfo[] = this.rechner
    .listeGeschlechterInfo()
    .sort((a: GeschlechtInfo, b: GeschlechtInfo) => {
      return a.sortNr - b.sortNr;
    });
  beziehungen: BeziehungInfo[] = this.rechner
    .listeBeziehungenInfo()
    .sort((a: BeziehungInfo, b: BeziehungInfo) => {
      return a.sortNr - b.sortNr;
    });

  // Forms vorbereiten
  minAlterAppComponent: number = Rechner.minAlter;
  maxAlterAppComponent: number = Rechner.maxAlter;
  minZinssatzAppComponent: number = Rechner.minZinssatz;
  maxZinssatzAppComponent: number = Rechner.maxZinssatz;

  // Forms erzeugen
  formGroup: UntypedFormGroup = new UntypedFormGroup({
    alterFormControl: new UntypedFormControl(null, [
      Validators.min(this.minAlterAppComponent),
      Validators.max(this.maxAlterAppComponent),
      Validators.required,
    ]),
    geschlechtFormControl: new UntypedFormControl(null, Validators.required),
    beziehungFormControl: new UntypedFormControl(null, Validators.required),
    zinssatzFormControl: new UntypedFormControl(null, [
      Validators.min(this.minZinssatzAppComponent),
      Validators.max(this.maxZinssatzAppComponent),
      Validators.required,
    ]),
  });

  // Funktion zur Berechnung
  gewaehlterVerwandschaftsgrad = '';
  ergebnis: Ergebnis | null = null;

  berechneErgebnis(): void {
    // Non-null assertion operator (da passendes Label zu gewähltem Value immer vorhanden)
    this.gewaehlterVerwandschaftsgrad = this.beziehungen.find(
      (element) =>
        element.beziehung === this.formGroup.controls.beziehungFormControl.value
    )!.label;
    const maxSchenkung: number = this.rechner.berechneMaximaleSchenkung(
      this.formGroup.controls.beziehungFormControl.value,
      this.formGroup.controls.geschlechtFormControl.value,
      this.formGroup.controls.alterFormControl.value,
      this.formGroup.controls.zinssatzFormControl.value
    );
    const steuer: number = this.rechner.berechneSteuerAufErwerb(
      this.rechner.berechneErwerbOhneNiessbrauch(
        maxSchenkung,
        this.formGroup.controls.beziehungFormControl.value
      ),
      this.formGroup.controls.beziehungFormControl.value
    );
    this.ergebnis = {
      steuerfreierBetrag: maxSchenkung,
      schenkungssteuerBetrag: steuer,
    };
  }

  // Initiale Berechung bei Klick auf Button
  onSubmit(): void {
    if (this.formGroup.valid) {
      this.berechneErgebnis();
      this.rechnerInitial = false;
    } else {
      this.gewaehlterVerwandschaftsgrad = '';
      this.ergebnis = null;
      this.markGroupDirty(this.formGroup);
    }
  }

  // Nachfolgende Updates bei "valueChanges"-Events
  ngOnInit(): void {
    this.formGroup.valueChanges.subscribe((changedValue) => {
      if (!this.rechnerInitial) {
        this.berechneErgebnis();
      }
    });
  }

  // Dirty-Funktionen
  markGroupDirty(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);
      if (control instanceof UntypedFormGroup) {
        this.markGroupDirty(control);
      } else if (control instanceof UntypedFormControl) {
        this.markControlDirty(control);
      } else if (control instanceof UntypedFormArray) {
        this.markArrayDirty(control);
      }
    });
  }

  markControlDirty(formControl: UntypedFormControl) {
    formControl.markAsDirty();
  }

  markArrayDirty(formArray: UntypedFormArray) {
    formArray.controls.forEach((control) => {
      switch (control.constructor.name) {
        case 'FormGroup':
          this.markGroupDirty(control as UntypedFormGroup);
          break;
        case 'FormControl':
          this.markControlDirty(control as UntypedFormControl);
          break;
        case 'FormArray':
          this.markArrayDirty(control as UntypedFormArray);
          break;
      }
    });
  }
}
