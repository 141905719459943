"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Rechner = void 0;
var rechner_1 = require("./rechner");
Object.defineProperty(exports, "Rechner", {
  enumerable: true,
  get: function () {
    return rechner_1.Rechner;
  }
});
