"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.errorMsgKeineVervielfaeltigerFuerJahr = exports.errorMsgUngueltigesAlter = exports.errorMsgUngueltigesGeschlecht = exports.errorMsgUngueltigesJahr = exports.errorMsgKeineQuelle = void 0;
exports.listeJahreszahlenVervielfaeltiger = listeJahreszahlenVervielfaeltiger;
exports.ermittleVerwendetesJahr = ermittleVerwendetesJahr;
exports.ermittleVerwendetesJahrZusatzdaten = ermittleVerwendetesJahrZusatzdaten;
exports.ermittleVervielfaeltiger = ermittleVervielfaeltiger;
const rechner_1 = require("./rechner");
// Hinweis zu den Vervielfältiger-Daten:
// Es sollten immer mindestens die Vervielfältiger-Werte der Jahre 2020 und 2021 vorhanden
// sein, weil sich die diversen Testfälle auf diese Werte beziehen. Neue Werte für weitere
// Jahre (2022 und später) können nach Bedarf ergänzt werden: entweder kumulativ (d.h. ohne
// Löschung alter Jahresscheiben) oder individuell (durch Ersetzung bzw. Entfernung nicht
// mehr relevanter Jahresscheiben).
// Die (aufwändigere, aber platzsparendere Alternative) ist die Beibehaltung nur einer oder
// zweier aktueller Jahresscheiben, dann müssen aber die betroffenen Testfälle allesamt
// entsprechend angepasst werden hinsichtlich der Jahreszahl und der erwarteten Resultate.
/*
————————————————————————————————————————————————————————————————————————————————
Interne Definitionen
————————————————————————————————————————————————————————————————————————————————
*/
// Fehlermeldungen
const errorMsgKeinVervielfaeltiger = 'Kein Vervielfältiger definiert';
// Definition Tabelle mit Quellenangaben zu Vervielfältiger-Werten
const TabelleVervielfaeltigerQuellen = [{
  jahr: 2020,
  typ: 'BMF-Schreiben',
  titel: 'Be­wer­tung ei­ner le­bens­läng­li­chen Nut­zung oder Leis­tung für Stich­ta­ge ab 1.1.2020',
  aktenplanzeichen: 'IV C 7 - S 3104/19/10001 :003',
  datum: '02.12.2019',
  url: 'https://www.bundesfinanzministerium.de/Content/DE/Downloads/BMF_Schreiben/Steuerarten/Erbschaft_Schenkungsteuerrecht/' + '2019-12-02-bewertung-eine-lebenslaenglichen-nutzung-oder-leistung-fuer-stichtage-ab-1-1-2020.html'
}, {
  jahr: 2021,
  typ: 'BMF-Schreiben',
  titel: 'Be­wer­tung ei­ner le­bens­läng­li­chen Nut­zung oder Leis­tung für Stich­ta­ge ab 1.1.2021',
  aktenplanzeichen: 'IV C 7 - S 3104/19/10001 :005',
  datum: '28.10.2020',
  url: 'https://www.bundesfinanzministerium.de/Content/DE/Downloads/BMF_Schreiben/Steuerarten/Erbschaft_Schenkungsteuerrecht/' + '2020-10-28-bewertung-einer-lebenslaenglichen-nutzung-oder-leistung-stichtage-ab-1-1-2021.html'
}, {
  jahr: 2022,
  typ: 'BMF-Schreiben',
  titel: 'Be­wer­tung ei­ner le­bens­läng­li­chen Nut­zung oder Leis­tung für Stich­ta­ge ab 1.1.2022',
  aktenplanzeichen: 'IV C 7 - S 3104/19/10001 :006',
  datum: '04.10.2021',
  url: 'https://www.bundesfinanzministerium.de/Content/DE/Downloads/BMF_Schreiben/Steuerarten/Erbschaft_Schenkungsteuerrecht/' + '2021-10-04-bewertung-einer-lebenslaenglichen-nutzung-oder-leistung-stichtage-ab-1-1-2022.html'
}, {
  jahr: 2023,
  typ: 'BMF-Schreiben',
  titel: 'Be­wer­tung ei­ner le­bens­läng­li­chen Nut­zung oder Leis­tung für Stich­ta­ge ab 1.1.2023',
  aktenplanzeichen: 'IV C 7 - S 3104/19/10001 :008',
  datum: '14.11.2022',
  url: 'https://www.bundesfinanzministerium.de/Content/DE/Downloads/BMF_Schreiben/Steuerarten/Erbschaft_Schenkungsteuerrecht/' + '2022-11-14-bewertung-einer-lebenslaenglichen-nutzung-oder-leistung-stichtage-ab-1-1-2023.html'
}, {
  jahr: 2024,
  typ: 'BMF-Schreiben',
  titel: 'Be­rech­nung ei­ner le­bens­läng­li­chen Nut­zung oder Leis­tung; Ver­viel­fäl­ti­ger für Be­wer­tungs­stich­ta­ge ab 1.Januar 2024',
  aktenplanzeichen: 'IV D 4 - S 3104/19/10001 :009',
  datum: '01.12.2023',
  url: 'https://www.bundesfinanzministerium.de/Content/DE/Downloads/BMF_Schreiben/Steuerarten/Erbschaft_Schenkungsteuerrecht/' + '2023-12-01-bewertung-einer-lebenslaenglichen-nutzung-oder-leistung-stichtage-ab-1-1-2024.html'
}, {
  jahr: 2025,
  typ: 'BMF-Schreiben',
  titel: 'Bewertung einer lebenslänglichen Nutzung oder Leistung; Vervielfältiger für Bewertungsstichtage ab 1. Januar 2025',
  aktenplanzeichen: 'V D 4 - S 3104/19/10001 :010',
  datum: '09.12.2024',
  url: 'https://www.bundesfinanzministerium.de/Content/DE/Downloads/BMF_Schreiben/Steuerarten/Erbschaft_Schenkungsteuerrecht/' + '2024-12-09-bewert-lebensl-nutzung-leistung-1-1-25.html'
}];
// Definition einer statischen Tabelle mit den Vervielfältiger-Werten
const TabelleVervielfaeltiger = [{
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 0
  },
  vervielfaeltiger: 18.402
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 1
  },
  vervielfaeltiger: 18.391
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 2
  },
  vervielfaeltiger: 18.376
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 3
  },
  vervielfaeltiger: 18.359
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 4
  },
  vervielfaeltiger: 18.341
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 5
  },
  vervielfaeltiger: 18.323
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 6
  },
  vervielfaeltiger: 18.303
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 7
  },
  vervielfaeltiger: 18.282
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 8
  },
  vervielfaeltiger: 18.261
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 9
  },
  vervielfaeltiger: 18.238
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 10
  },
  vervielfaeltiger: 18.213
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 11
  },
  vervielfaeltiger: 18.188
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 12
  },
  vervielfaeltiger: 18.161
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 13
  },
  vervielfaeltiger: 18.132
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 14
  },
  vervielfaeltiger: 18.102
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 15
  },
  vervielfaeltiger: 18.07
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 16
  },
  vervielfaeltiger: 18.037
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 17
  },
  vervielfaeltiger: 18.002
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 18
  },
  vervielfaeltiger: 17.965
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 19
  },
  vervielfaeltiger: 17.927
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 20
  },
  vervielfaeltiger: 17.887
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 21
  },
  vervielfaeltiger: 17.844
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 22
  },
  vervielfaeltiger: 17.799
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 23
  },
  vervielfaeltiger: 17.752
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 24
  },
  vervielfaeltiger: 17.702
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 25
  },
  vervielfaeltiger: 17.649
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 26
  },
  vervielfaeltiger: 17.594
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 27
  },
  vervielfaeltiger: 17.536
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 28
  },
  vervielfaeltiger: 17.474
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 29
  },
  vervielfaeltiger: 17.409
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 30
  },
  vervielfaeltiger: 17.342
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 31
  },
  vervielfaeltiger: 17.269
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 32
  },
  vervielfaeltiger: 17.194
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 33
  },
  vervielfaeltiger: 17.115
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 34
  },
  vervielfaeltiger: 17.032
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 35
  },
  vervielfaeltiger: 16.944
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 36
  },
  vervielfaeltiger: 16.852
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 37
  },
  vervielfaeltiger: 16.755
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 38
  },
  vervielfaeltiger: 16.653
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 39
  },
  vervielfaeltiger: 16.548
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 40
  },
  vervielfaeltiger: 16.435
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 41
  },
  vervielfaeltiger: 16.318
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 42
  },
  vervielfaeltiger: 16.194
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 43
  },
  vervielfaeltiger: 16.065
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 44
  },
  vervielfaeltiger: 15.93
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 45
  },
  vervielfaeltiger: 15.788
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 46
  },
  vervielfaeltiger: 15.64
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 47
  },
  vervielfaeltiger: 15.485
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 48
  },
  vervielfaeltiger: 15.323
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 49
  },
  vervielfaeltiger: 15.153
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 50
  },
  vervielfaeltiger: 14.979
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 51
  },
  vervielfaeltiger: 14.797
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 52
  },
  vervielfaeltiger: 14.605
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 53
  },
  vervielfaeltiger: 14.408
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 54
  },
  vervielfaeltiger: 14.204
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 55
  },
  vervielfaeltiger: 13.993
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 56
  },
  vervielfaeltiger: 13.775
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 57
  },
  vervielfaeltiger: 13.549
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 58
  },
  vervielfaeltiger: 13.316
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 59
  },
  vervielfaeltiger: 13.078
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 60
  },
  vervielfaeltiger: 12.833
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 61
  },
  vervielfaeltiger: 12.58
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 62
  },
  vervielfaeltiger: 12.32
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 63
  },
  vervielfaeltiger: 12.056
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 64
  },
  vervielfaeltiger: 11.784
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 65
  },
  vervielfaeltiger: 11.506
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 66
  },
  vervielfaeltiger: 11.22
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 67
  },
  vervielfaeltiger: 10.926
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 68
  },
  vervielfaeltiger: 10.626
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 69
  },
  vervielfaeltiger: 10.318
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 70
  },
  vervielfaeltiger: 10.008
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 71
  },
  vervielfaeltiger: 9.682
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 72
  },
  vervielfaeltiger: 9.353
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 73
  },
  vervielfaeltiger: 9.017
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 74
  },
  vervielfaeltiger: 8.675
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 75
  },
  vervielfaeltiger: 8.326
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 76
  },
  vervielfaeltiger: 7.965
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 77
  },
  vervielfaeltiger: 7.603
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 78
  },
  vervielfaeltiger: 7.242
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 79
  },
  vervielfaeltiger: 6.875
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 80
  },
  vervielfaeltiger: 6.509
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 81
  },
  vervielfaeltiger: 6.138
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 82
  },
  vervielfaeltiger: 5.784
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 83
  },
  vervielfaeltiger: 5.441
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 84
  },
  vervielfaeltiger: 5.097
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 85
  },
  vervielfaeltiger: 4.773
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 86
  },
  vervielfaeltiger: 4.456
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 87
  },
  vervielfaeltiger: 4.156
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 88
  },
  vervielfaeltiger: 3.874
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 89
  },
  vervielfaeltiger: 3.61
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 90
  },
  vervielfaeltiger: 3.366
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 91
  },
  vervielfaeltiger: 3.126
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 92
  },
  vervielfaeltiger: 2.899
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 93
  },
  vervielfaeltiger: 2.704
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 94
  },
  vervielfaeltiger: 2.506
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 95
  },
  vervielfaeltiger: 2.349
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 96
  },
  vervielfaeltiger: 2.191
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 97
  },
  vervielfaeltiger: 2.067
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 98
  },
  vervielfaeltiger: 1.942
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 99
  },
  vervielfaeltiger: 1.834
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Mann',
    alter: 100
  },
  vervielfaeltiger: 1.735
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 0
  },
  vervielfaeltiger: 18.465
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 1
  },
  vervielfaeltiger: 18.457
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 2
  },
  vervielfaeltiger: 18.444
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 3
  },
  vervielfaeltiger: 18.432
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 4
  },
  vervielfaeltiger: 18.418
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 5
  },
  vervielfaeltiger: 18.403
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 6
  },
  vervielfaeltiger: 18.388
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 7
  },
  vervielfaeltiger: 18.372
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 8
  },
  vervielfaeltiger: 18.355
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 9
  },
  vervielfaeltiger: 18.337
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 10
  },
  vervielfaeltiger: 18.319
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 11
  },
  vervielfaeltiger: 18.299
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 12
  },
  vervielfaeltiger: 18.278
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 13
  },
  vervielfaeltiger: 18.256
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 14
  },
  vervielfaeltiger: 18.232
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 15
  },
  vervielfaeltiger: 18.208
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 16
  },
  vervielfaeltiger: 18.182
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 17
  },
  vervielfaeltiger: 18.155
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 18
  },
  vervielfaeltiger: 18.126
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 19
  },
  vervielfaeltiger: 18.096
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 20
  },
  vervielfaeltiger: 18.064
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 21
  },
  vervielfaeltiger: 18.03
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 22
  },
  vervielfaeltiger: 17.995
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 23
  },
  vervielfaeltiger: 17.957
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 24
  },
  vervielfaeltiger: 17.918
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 25
  },
  vervielfaeltiger: 17.876
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 26
  },
  vervielfaeltiger: 17.833
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 27
  },
  vervielfaeltiger: 17.787
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 28
  },
  vervielfaeltiger: 17.738
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 29
  },
  vervielfaeltiger: 17.687
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 30
  },
  vervielfaeltiger: 17.633
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 31
  },
  vervielfaeltiger: 17.576
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 32
  },
  vervielfaeltiger: 17.516
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 33
  },
  vervielfaeltiger: 17.454
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 34
  },
  vervielfaeltiger: 17.387
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 35
  },
  vervielfaeltiger: 17.317
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 36
  },
  vervielfaeltiger: 17.243
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 37
  },
  vervielfaeltiger: 17.167
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 38
  },
  vervielfaeltiger: 17.085
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 39
  },
  vervielfaeltiger: 16.999
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 40
  },
  vervielfaeltiger: 16.909
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 41
  },
  vervielfaeltiger: 16.815
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 42
  },
  vervielfaeltiger: 16.716
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 43
  },
  vervielfaeltiger: 16.612
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 44
  },
  vervielfaeltiger: 16.501
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 45
  },
  vervielfaeltiger: 16.386
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 46
  },
  vervielfaeltiger: 16.267
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 47
  },
  vervielfaeltiger: 16.139
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 48
  },
  vervielfaeltiger: 16.007
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 49
  },
  vervielfaeltiger: 15.869
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 50
  },
  vervielfaeltiger: 15.723
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 51
  },
  vervielfaeltiger: 15.571
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 52
  },
  vervielfaeltiger: 15.412
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 53
  },
  vervielfaeltiger: 15.247
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 54
  },
  vervielfaeltiger: 15.073
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 55
  },
  vervielfaeltiger: 14.893
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 56
  },
  vervielfaeltiger: 14.704
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 57
  },
  vervielfaeltiger: 14.508
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 58
  },
  vervielfaeltiger: 14.304
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 59
  },
  vervielfaeltiger: 14.093
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 60
  },
  vervielfaeltiger: 13.871
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 61
  },
  vervielfaeltiger: 13.642
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 62
  },
  vervielfaeltiger: 13.404
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 63
  },
  vervielfaeltiger: 13.156
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 64
  },
  vervielfaeltiger: 12.898
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 65
  },
  vervielfaeltiger: 12.632
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 66
  },
  vervielfaeltiger: 12.357
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 67
  },
  vervielfaeltiger: 12.07
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 68
  },
  vervielfaeltiger: 11.773
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 69
  },
  vervielfaeltiger: 11.471
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 70
  },
  vervielfaeltiger: 11.155
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 71
  },
  vervielfaeltiger: 10.83
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 72
  },
  vervielfaeltiger: 10.495
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 73
  },
  vervielfaeltiger: 10.146
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 74
  },
  vervielfaeltiger: 9.792
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 75
  },
  vervielfaeltiger: 9.422
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 76
  },
  vervielfaeltiger: 9.043
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 77
  },
  vervielfaeltiger: 8.654
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 78
  },
  vervielfaeltiger: 8.254
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 79
  },
  vervielfaeltiger: 7.85
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 80
  },
  vervielfaeltiger: 7.448
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 81
  },
  vervielfaeltiger: 7.044
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 82
  },
  vervielfaeltiger: 6.645
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 83
  },
  vervielfaeltiger: 6.252
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 84
  },
  vervielfaeltiger: 5.867
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 85
  },
  vervielfaeltiger: 5.484
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 86
  },
  vervielfaeltiger: 5.118
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 87
  },
  vervielfaeltiger: 4.773
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 88
  },
  vervielfaeltiger: 4.434
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 89
  },
  vervielfaeltiger: 4.117
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 90
  },
  vervielfaeltiger: 3.818
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 91
  },
  vervielfaeltiger: 3.537
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 92
  },
  vervielfaeltiger: 3.283
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 93
  },
  vervielfaeltiger: 3.051
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 94
  },
  vervielfaeltiger: 2.84
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 95
  },
  vervielfaeltiger: 2.644
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 96
  },
  vervielfaeltiger: 2.462
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 97
  },
  vervielfaeltiger: 2.34
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 98
  },
  vervielfaeltiger: 2.209
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 99
  },
  vervielfaeltiger: 2.076
}, {
  key: {
    jahr: 2020,
    geschlecht: 'Frau',
    alter: 100
  },
  vervielfaeltiger: 1.951
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 0
  },
  vervielfaeltiger: 18.404
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 1
  },
  vervielfaeltiger: 18.393
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 2
  },
  vervielfaeltiger: 18.378
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 3
  },
  vervielfaeltiger: 18.361
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 4
  },
  vervielfaeltiger: 18.344
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 5
  },
  vervielfaeltiger: 18.325
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 6
  },
  vervielfaeltiger: 18.306
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 7
  },
  vervielfaeltiger: 18.285
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 8
  },
  vervielfaeltiger: 18.264
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 9
  },
  vervielfaeltiger: 18.241
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 10
  },
  vervielfaeltiger: 18.217
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 11
  },
  vervielfaeltiger: 18.191
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 12
  },
  vervielfaeltiger: 18.165
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 13
  },
  vervielfaeltiger: 18.136
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 14
  },
  vervielfaeltiger: 18.106
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 15
  },
  vervielfaeltiger: 18.075
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 16
  },
  vervielfaeltiger: 18.042
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 17
  },
  vervielfaeltiger: 18.007
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 18
  },
  vervielfaeltiger: 17.971
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 19
  },
  vervielfaeltiger: 17.933
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 20
  },
  vervielfaeltiger: 17.893
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 21
  },
  vervielfaeltiger: 17.85
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 22
  },
  vervielfaeltiger: 17.806
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 23
  },
  vervielfaeltiger: 17.759
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 24
  },
  vervielfaeltiger: 17.709
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 25
  },
  vervielfaeltiger: 17.657
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 26
  },
  vervielfaeltiger: 17.602
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 27
  },
  vervielfaeltiger: 17.544
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 28
  },
  vervielfaeltiger: 17.483
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 29
  },
  vervielfaeltiger: 17.418
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 30
  },
  vervielfaeltiger: 17.351
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 31
  },
  vervielfaeltiger: 17.279
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 32
  },
  vervielfaeltiger: 17.204
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 33
  },
  vervielfaeltiger: 17.126
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 34
  },
  vervielfaeltiger: 17.044
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 35
  },
  vervielfaeltiger: 16.956
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 36
  },
  vervielfaeltiger: 16.864
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 37
  },
  vervielfaeltiger: 16.768
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 38
  },
  vervielfaeltiger: 16.668
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 39
  },
  vervielfaeltiger: 16.561
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 40
  },
  vervielfaeltiger: 16.451
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 41
  },
  vervielfaeltiger: 16.333
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 42
  },
  vervielfaeltiger: 16.212
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 43
  },
  vervielfaeltiger: 16.083
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 44
  },
  vervielfaeltiger: 15.949
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 45
  },
  vervielfaeltiger: 15.808
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 46
  },
  vervielfaeltiger: 15.659
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 47
  },
  vervielfaeltiger: 15.505
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 48
  },
  vervielfaeltiger: 15.345
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 49
  },
  vervielfaeltiger: 15.176
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 50
  },
  vervielfaeltiger: 15.001
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 51
  },
  vervielfaeltiger: 14.819
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 52
  },
  vervielfaeltiger: 14.629
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 53
  },
  vervielfaeltiger: 14.433
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 54
  },
  vervielfaeltiger: 14.228
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 55
  },
  vervielfaeltiger: 14.018
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 56
  },
  vervielfaeltiger: 13.799
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 57
  },
  vervielfaeltiger: 13.574
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 58
  },
  vervielfaeltiger: 13.342
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 59
  },
  vervielfaeltiger: 13.102
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 60
  },
  vervielfaeltiger: 12.858
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 61
  },
  vervielfaeltiger: 12.606
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 62
  },
  vervielfaeltiger: 12.347
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 63
  },
  vervielfaeltiger: 12.081
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 64
  },
  vervielfaeltiger: 11.81
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 65
  },
  vervielfaeltiger: 11.532
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 66
  },
  vervielfaeltiger: 11.247
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 67
  },
  vervielfaeltiger: 10.955
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 68
  },
  vervielfaeltiger: 10.656
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 69
  },
  vervielfaeltiger: 10.354
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 70
  },
  vervielfaeltiger: 10.04
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 71
  },
  vervielfaeltiger: 9.72
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 72
  },
  vervielfaeltiger: 9.393
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 73
  },
  vervielfaeltiger: 9.059
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 74
  },
  vervielfaeltiger: 8.712
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 75
  },
  vervielfaeltiger: 8.37
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 76
  },
  vervielfaeltiger: 8.017
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 77
  },
  vervielfaeltiger: 7.657
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 78
  },
  vervielfaeltiger: 7.291
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 79
  },
  vervielfaeltiger: 6.925
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 80
  },
  vervielfaeltiger: 6.561
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 81
  },
  vervielfaeltiger: 6.192
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 82
  },
  vervielfaeltiger: 5.832
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 83
  },
  vervielfaeltiger: 5.484
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 84
  },
  vervielfaeltiger: 5.133
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 85
  },
  vervielfaeltiger: 4.803
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 86
  },
  vervielfaeltiger: 4.487
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 87
  },
  vervielfaeltiger: 4.18
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 88
  },
  vervielfaeltiger: 3.889
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 89
  },
  vervielfaeltiger: 3.626
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 90
  },
  vervielfaeltiger: 3.374
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 91
  },
  vervielfaeltiger: 3.143
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 92
  },
  vervielfaeltiger: 2.916
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 93
  },
  vervielfaeltiger: 2.712
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 94
  },
  vervielfaeltiger: 2.532
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 95
  },
  vervielfaeltiger: 2.358
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 96
  },
  vervielfaeltiger: 2.2
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 97
  },
  vervielfaeltiger: 2.067
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 98
  },
  vervielfaeltiger: 1.96
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 99
  },
  vervielfaeltiger: 1.843
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Mann',
    alter: 100
  },
  vervielfaeltiger: 1.744
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 0
  },
  vervielfaeltiger: 18.466
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 1
  },
  vervielfaeltiger: 18.458
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 2
  },
  vervielfaeltiger: 18.445
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 3
  },
  vervielfaeltiger: 18.433
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 4
  },
  vervielfaeltiger: 18.419
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 5
  },
  vervielfaeltiger: 18.405
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 6
  },
  vervielfaeltiger: 18.389
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 7
  },
  vervielfaeltiger: 18.374
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 8
  },
  vervielfaeltiger: 18.357
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 9
  },
  vervielfaeltiger: 18.339
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 10
  },
  vervielfaeltiger: 18.32
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 11
  },
  vervielfaeltiger: 18.3
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 12
  },
  vervielfaeltiger: 18.279
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 13
  },
  vervielfaeltiger: 18.257
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 14
  },
  vervielfaeltiger: 18.234
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 15
  },
  vervielfaeltiger: 18.21
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 16
  },
  vervielfaeltiger: 18.184
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 17
  },
  vervielfaeltiger: 18.157
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 18
  },
  vervielfaeltiger: 18.128
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 19
  },
  vervielfaeltiger: 18.098
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 20
  },
  vervielfaeltiger: 18.067
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 21
  },
  vervielfaeltiger: 18.033
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 22
  },
  vervielfaeltiger: 17.998
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 23
  },
  vervielfaeltiger: 17.96
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 24
  },
  vervielfaeltiger: 17.921
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 25
  },
  vervielfaeltiger: 17.88
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 26
  },
  vervielfaeltiger: 17.837
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 27
  },
  vervielfaeltiger: 17.791
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 28
  },
  vervielfaeltiger: 17.742
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 29
  },
  vervielfaeltiger: 17.691
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 30
  },
  vervielfaeltiger: 17.637
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 31
  },
  vervielfaeltiger: 17.581
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 32
  },
  vervielfaeltiger: 17.521
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 33
  },
  vervielfaeltiger: 17.458
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 34
  },
  vervielfaeltiger: 17.392
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 35
  },
  vervielfaeltiger: 17.323
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 36
  },
  vervielfaeltiger: 17.25
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 37
  },
  vervielfaeltiger: 17.173
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 38
  },
  vervielfaeltiger: 17.092
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 39
  },
  vervielfaeltiger: 17.007
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 40
  },
  vervielfaeltiger: 16.917
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 41
  },
  vervielfaeltiger: 16.823
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 42
  },
  vervielfaeltiger: 16.724
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 43
  },
  vervielfaeltiger: 16.62
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 44
  },
  vervielfaeltiger: 16.511
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 45
  },
  vervielfaeltiger: 16.396
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 46
  },
  vervielfaeltiger: 16.276
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 47
  },
  vervielfaeltiger: 16.15
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 48
  },
  vervielfaeltiger: 16.018
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 49
  },
  vervielfaeltiger: 15.879
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 50
  },
  vervielfaeltiger: 15.734
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 51
  },
  vervielfaeltiger: 15.582
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 52
  },
  vervielfaeltiger: 15.424
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 53
  },
  vervielfaeltiger: 15.26
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 54
  },
  vervielfaeltiger: 15.087
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 55
  },
  vervielfaeltiger: 14.905
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 56
  },
  vervielfaeltiger: 14.717
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 57
  },
  vervielfaeltiger: 14.521
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 58
  },
  vervielfaeltiger: 14.318
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 59
  },
  vervielfaeltiger: 14.105
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 60
  },
  vervielfaeltiger: 13.884
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 61
  },
  vervielfaeltiger: 13.655
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 62
  },
  vervielfaeltiger: 13.418
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 63
  },
  vervielfaeltiger: 13.17
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 64
  },
  vervielfaeltiger: 12.914
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 65
  },
  vervielfaeltiger: 12.648
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 66
  },
  vervielfaeltiger: 12.374
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 67
  },
  vervielfaeltiger: 12.088
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 68
  },
  vervielfaeltiger: 11.792
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 69
  },
  vervielfaeltiger: 11.486
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 70
  },
  vervielfaeltiger: 11.171
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 71
  },
  vervielfaeltiger: 10.851
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 72
  },
  vervielfaeltiger: 10.513
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 73
  },
  vervielfaeltiger: 10.169
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 74
  },
  vervielfaeltiger: 9.815
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 75
  },
  vervielfaeltiger: 9.452
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 76
  },
  vervielfaeltiger: 9.074
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 77
  },
  vervielfaeltiger: 8.691
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 78
  },
  vervielfaeltiger: 8.293
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 79
  },
  vervielfaeltiger: 7.89
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 80
  },
  vervielfaeltiger: 7.484
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 81
  },
  vervielfaeltiger: 7.075
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 82
  },
  vervielfaeltiger: 6.677
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 83
  },
  vervielfaeltiger: 6.279
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 84
  },
  vervielfaeltiger: 5.887
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 85
  },
  vervielfaeltiger: 5.505
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 86
  },
  vervielfaeltiger: 5.14
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 87
  },
  vervielfaeltiger: 4.788
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 88
  },
  vervielfaeltiger: 4.449
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 89
  },
  vervielfaeltiger: 4.133
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 90
  },
  vervielfaeltiger: 3.826
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 91
  },
  vervielfaeltiger: 3.545
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 92
  },
  vervielfaeltiger: 3.292
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 93
  },
  vervielfaeltiger: 3.059
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 94
  },
  vervielfaeltiger: 2.849
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 95
  },
  vervielfaeltiger: 2.652
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 96
  },
  vervielfaeltiger: 2.48
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 97
  },
  vervielfaeltiger: 2.332
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 98
  },
  vervielfaeltiger: 2.191
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 99
  },
  vervielfaeltiger: 2.058
}, {
  key: {
    jahr: 2021,
    geschlecht: 'Frau',
    alter: 100
  },
  vervielfaeltiger: 1.933
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 0
  },
  vervielfaeltiger: 18.405
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 1
  },
  vervielfaeltiger: 18.393
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 2
  },
  vervielfaeltiger: 18.378
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 3
  },
  vervielfaeltiger: 18.361
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 4
  },
  vervielfaeltiger: 18.344
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 5
  },
  vervielfaeltiger: 18.325
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 6
  },
  vervielfaeltiger: 18.306
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 7
  },
  vervielfaeltiger: 18.285
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 8
  },
  vervielfaeltiger: 18.264
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 9
  },
  vervielfaeltiger: 18.241
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 10
  },
  vervielfaeltiger: 18.217
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 11
  },
  vervielfaeltiger: 18.191
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 12
  },
  vervielfaeltiger: 18.165
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 13
  },
  vervielfaeltiger: 18.136
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 14
  },
  vervielfaeltiger: 18.106
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 15
  },
  vervielfaeltiger: 18.075
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 16
  },
  vervielfaeltiger: 18.042
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 17
  },
  vervielfaeltiger: 18.007
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 18
  },
  vervielfaeltiger: 17.971
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 19
  },
  vervielfaeltiger: 17.933
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 20
  },
  vervielfaeltiger: 17.893
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 21
  },
  vervielfaeltiger: 17.85
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 22
  },
  vervielfaeltiger: 17.805
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 23
  },
  vervielfaeltiger: 17.759
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 24
  },
  vervielfaeltiger: 17.709
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 25
  },
  vervielfaeltiger: 17.657
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 26
  },
  vervielfaeltiger: 17.602
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 27
  },
  vervielfaeltiger: 17.544
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 28
  },
  vervielfaeltiger: 17.483
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 29
  },
  vervielfaeltiger: 17.418
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 30
  },
  vervielfaeltiger: 17.35
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 31
  },
  vervielfaeltiger: 17.279
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 32
  },
  vervielfaeltiger: 17.204
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 33
  },
  vervielfaeltiger: 17.126
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 34
  },
  vervielfaeltiger: 17.043
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 35
  },
  vervielfaeltiger: 16.955
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 36
  },
  vervielfaeltiger: 16.863
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 37
  },
  vervielfaeltiger: 16.767
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 38
  },
  vervielfaeltiger: 16.666
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 39
  },
  vervielfaeltiger: 16.561
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 40
  },
  vervielfaeltiger: 16.449
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 41
  },
  vervielfaeltiger: 16.333
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 42
  },
  vervielfaeltiger: 16.212
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 43
  },
  vervielfaeltiger: 16.083
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 44
  },
  vervielfaeltiger: 15.949
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 45
  },
  vervielfaeltiger: 15.808
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 46
  },
  vervielfaeltiger: 15.659
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 47
  },
  vervielfaeltiger: 15.505
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 48
  },
  vervielfaeltiger: 15.345
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 49
  },
  vervielfaeltiger: 15.176
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 50
  },
  vervielfaeltiger: 15.001
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 51
  },
  vervielfaeltiger: 14.819
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 52
  },
  vervielfaeltiger: 14.629
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 53
  },
  vervielfaeltiger: 14.433
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 54
  },
  vervielfaeltiger: 14.228
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 55
  },
  vervielfaeltiger: 14.016
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 56
  },
  vervielfaeltiger: 13.799
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 57
  },
  vervielfaeltiger: 13.571
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 58
  },
  vervielfaeltiger: 13.339
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 59
  },
  vervielfaeltiger: 13.099
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 60
  },
  vervielfaeltiger: 12.852
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 61
  },
  vervielfaeltiger: 12.6
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 62
  },
  vervielfaeltiger: 12.34
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 63
  },
  vervielfaeltiger: 12.074
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 64
  },
  vervielfaeltiger: 11.803
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 65
  },
  vervielfaeltiger: 11.525
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 66
  },
  vervielfaeltiger: 11.239
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 67
  },
  vervielfaeltiger: 10.951
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 68
  },
  vervielfaeltiger: 10.652
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 69
  },
  vervielfaeltiger: 10.349
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 70
  },
  vervielfaeltiger: 10.036
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 71
  },
  vervielfaeltiger: 9.715
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 72
  },
  vervielfaeltiger: 9.393
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 73
  },
  vervielfaeltiger: 9.059
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 74
  },
  vervielfaeltiger: 8.718
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 75
  },
  vervielfaeltiger: 8.37
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 76
  },
  vervielfaeltiger: 8.022
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 77
  },
  vervielfaeltiger: 7.669
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 78
  },
  vervielfaeltiger: 7.303
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 79
  },
  vervielfaeltiger: 6.938
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 80
  },
  vervielfaeltiger: 6.567
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 81
  },
  vervielfaeltiger: 6.205
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 82
  },
  vervielfaeltiger: 5.846
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 83
  },
  vervielfaeltiger: 5.484
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 84
  },
  vervielfaeltiger: 5.14
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 85
  },
  vervielfaeltiger: 4.803
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 86
  },
  vervielfaeltiger: 4.479
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 87
  },
  vervielfaeltiger: 4.172
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 88
  },
  vervielfaeltiger: 3.882
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 89
  },
  vervielfaeltiger: 3.61
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 90
  },
  vervielfaeltiger: 3.366
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 91
  },
  vervielfaeltiger: 3.134
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 92
  },
  vervielfaeltiger: 2.908
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 93
  },
  vervielfaeltiger: 2.712
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 94
  },
  vervielfaeltiger: 2.523
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 95
  },
  vervielfaeltiger: 2.367
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 96
  },
  vervielfaeltiger: 2.226
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 97
  },
  vervielfaeltiger: 2.094
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 98
  },
  vervielfaeltiger: 1.978
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 99
  },
  vervielfaeltiger: 1.852
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Mann',
    alter: 100
  },
  vervielfaeltiger: 1.753
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 0
  },
  vervielfaeltiger: 18.467
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 1
  },
  vervielfaeltiger: 18.458
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 2
  },
  vervielfaeltiger: 18.446
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 3
  },
  vervielfaeltiger: 18.433
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 4
  },
  vervielfaeltiger: 18.42
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 5
  },
  vervielfaeltiger: 18.405
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 6
  },
  vervielfaeltiger: 18.39
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 7
  },
  vervielfaeltiger: 18.374
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 8
  },
  vervielfaeltiger: 18.357
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 9
  },
  vervielfaeltiger: 18.34
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 10
  },
  vervielfaeltiger: 18.321
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 11
  },
  vervielfaeltiger: 18.301
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 12
  },
  vervielfaeltiger: 18.28
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 13
  },
  vervielfaeltiger: 18.258
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 14
  },
  vervielfaeltiger: 18.235
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 15
  },
  vervielfaeltiger: 18.211
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 16
  },
  vervielfaeltiger: 18.185
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 17
  },
  vervielfaeltiger: 18.158
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 18
  },
  vervielfaeltiger: 18.129
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 19
  },
  vervielfaeltiger: 18.099
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 20
  },
  vervielfaeltiger: 18.068
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 21
  },
  vervielfaeltiger: 18.034
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 22
  },
  vervielfaeltiger: 17.999
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 23
  },
  vervielfaeltiger: 17.962
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 24
  },
  vervielfaeltiger: 17.922
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 25
  },
  vervielfaeltiger: 17.881
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 26
  },
  vervielfaeltiger: 17.838
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 27
  },
  vervielfaeltiger: 17.792
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 28
  },
  vervielfaeltiger: 17.744
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 29
  },
  vervielfaeltiger: 17.693
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 30
  },
  vervielfaeltiger: 17.639
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 31
  },
  vervielfaeltiger: 17.582
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 32
  },
  vervielfaeltiger: 17.523
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 33
  },
  vervielfaeltiger: 17.46
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 34
  },
  vervielfaeltiger: 17.394
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 35
  },
  vervielfaeltiger: 17.325
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 36
  },
  vervielfaeltiger: 17.252
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 37
  },
  vervielfaeltiger: 17.176
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 38
  },
  vervielfaeltiger: 17.095
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 39
  },
  vervielfaeltiger: 17.009
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 40
  },
  vervielfaeltiger: 16.92
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 41
  },
  vervielfaeltiger: 16.826
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 42
  },
  vervielfaeltiger: 16.727
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 43
  },
  vervielfaeltiger: 16.624
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 44
  },
  vervielfaeltiger: 16.514
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 45
  },
  vervielfaeltiger: 16.4
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 46
  },
  vervielfaeltiger: 16.28
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 47
  },
  vervielfaeltiger: 16.154
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 48
  },
  vervielfaeltiger: 16.021
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 49
  },
  vervielfaeltiger: 15.884
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 50
  },
  vervielfaeltiger: 15.739
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 51
  },
  vervielfaeltiger: 15.587
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 52
  },
  vervielfaeltiger: 15.429
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 53
  },
  vervielfaeltiger: 15.263
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 54
  },
  vervielfaeltiger: 15.091
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 55
  },
  vervielfaeltiger: 14.909
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 56
  },
  vervielfaeltiger: 14.721
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 57
  },
  vervielfaeltiger: 14.525
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 58
  },
  vervielfaeltiger: 14.32
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 59
  },
  vervielfaeltiger: 14.11
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 60
  },
  vervielfaeltiger: 13.889
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 61
  },
  vervielfaeltiger: 13.658
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 62
  },
  vervielfaeltiger: 13.421
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 63
  },
  vervielfaeltiger: 13.173
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 64
  },
  vervielfaeltiger: 12.917
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 65
  },
  vervielfaeltiger: 12.652
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 66
  },
  vervielfaeltiger: 12.378
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 67
  },
  vervielfaeltiger: 12.095
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 68
  },
  vervielfaeltiger: 11.795
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 69
  },
  vervielfaeltiger: 11.49
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 70
  },
  vervielfaeltiger: 11.175
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 71
  },
  vervielfaeltiger: 10.851
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 72
  },
  vervielfaeltiger: 10.517
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 73
  },
  vervielfaeltiger: 10.174
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 74
  },
  vervielfaeltiger: 9.82
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 75
  },
  vervielfaeltiger: 9.457
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 76
  },
  vervielfaeltiger: 9.084
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 77
  },
  vervielfaeltiger: 8.702
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 78
  },
  vervielfaeltiger: 8.31
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 79
  },
  vervielfaeltiger: 7.908
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 80
  },
  vervielfaeltiger: 7.502
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 81
  },
  vervielfaeltiger: 7.094
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 82
  },
  vervielfaeltiger: 6.69
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 83
  },
  vervielfaeltiger: 6.285
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 84
  },
  vervielfaeltiger: 5.894
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 85
  },
  vervielfaeltiger: 5.512
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 86
  },
  vervielfaeltiger: 5.14
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 87
  },
  vervielfaeltiger: 4.78
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 88
  },
  vervielfaeltiger: 4.449
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 89
  },
  vervielfaeltiger: 4.125
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 90
  },
  vervielfaeltiger: 3.826
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 91
  },
  vervielfaeltiger: 3.545
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 92
  },
  vervielfaeltiger: 3.283
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 93
  },
  vervielfaeltiger: 3.051
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 94
  },
  vervielfaeltiger: 2.84
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 95
  },
  vervielfaeltiger: 2.644
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 96
  },
  vervielfaeltiger: 2.471
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 97
  },
  vervielfaeltiger: 2.323
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 98
  },
  vervielfaeltiger: 2.173
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 99
  },
  vervielfaeltiger: 2.031
}, {
  key: {
    jahr: 2022,
    geschlecht: 'Frau',
    alter: 100
  },
  vervielfaeltiger: 1.915
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 0
  },
  vervielfaeltiger: 18.403
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 1
  },
  vervielfaeltiger: 18.392
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 2
  },
  vervielfaeltiger: 18.376
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 3
  },
  vervielfaeltiger: 18.359
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 4
  },
  vervielfaeltiger: 18.342
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 5
  },
  vervielfaeltiger: 18.323
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 6
  },
  vervielfaeltiger: 18.304
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 7
  },
  vervielfaeltiger: 18.283
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 8
  },
  vervielfaeltiger: 18.261
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 9
  },
  vervielfaeltiger: 18.238
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 10
  },
  vervielfaeltiger: 18.214
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 11
  },
  vervielfaeltiger: 18.188
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 12
  },
  vervielfaeltiger: 18.161
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 13
  },
  vervielfaeltiger: 18.133
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 14
  },
  vervielfaeltiger: 18.103
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 15
  },
  vervielfaeltiger: 18.071
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 16
  },
  vervielfaeltiger: 18.038
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 17
  },
  vervielfaeltiger: 18.003
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 18
  },
  vervielfaeltiger: 17.967
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 19
  },
  vervielfaeltiger: 17.928
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 20
  },
  vervielfaeltiger: 17.888
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 21
  },
  vervielfaeltiger: 17.845
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 22
  },
  vervielfaeltiger: 17.8
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 23
  },
  vervielfaeltiger: 17.753
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 24
  },
  vervielfaeltiger: 17.703
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 25
  },
  vervielfaeltiger: 17.65
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 26
  },
  vervielfaeltiger: 17.595
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 27
  },
  vervielfaeltiger: 17.537
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 28
  },
  vervielfaeltiger: 17.475
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 29
  },
  vervielfaeltiger: 17.411
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 30
  },
  vervielfaeltiger: 17.342
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 31
  },
  vervielfaeltiger: 17.271
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 32
  },
  vervielfaeltiger: 17.196
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 33
  },
  vervielfaeltiger: 17.116
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 34
  },
  vervielfaeltiger: 17.032
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 35
  },
  vervielfaeltiger: 16.945
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 36
  },
  vervielfaeltiger: 16.853
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 37
  },
  vervielfaeltiger: 16.756
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 38
  },
  vervielfaeltiger: 16.655
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 39
  },
  vervielfaeltiger: 16.549
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 40
  },
  vervielfaeltiger: 16.436
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 41
  },
  vervielfaeltiger: 16.319
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 42
  },
  vervielfaeltiger: 16.197
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 43
  },
  vervielfaeltiger: 16.068
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 44
  },
  vervielfaeltiger: 15.933
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 45
  },
  vervielfaeltiger: 15.791
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 46
  },
  vervielfaeltiger: 15.643
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 47
  },
  vervielfaeltiger: 15.488
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 48
  },
  vervielfaeltiger: 15.327
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 49
  },
  vervielfaeltiger: 15.157
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 50
  },
  vervielfaeltiger: 14.983
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 51
  },
  vervielfaeltiger: 14.801
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 52
  },
  vervielfaeltiger: 14.609
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 53
  },
  vervielfaeltiger: 14.41
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 54
  },
  vervielfaeltiger: 14.207
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 55
  },
  vervielfaeltiger: 13.993
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 56
  },
  vervielfaeltiger: 13.772
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 57
  },
  vervielfaeltiger: 13.546
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 58
  },
  vervielfaeltiger: 13.31
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 59
  },
  vervielfaeltiger: 13.069
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 60
  },
  vervielfaeltiger: 12.824
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 61
  },
  vervielfaeltiger: 12.567
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 62
  },
  vervielfaeltiger: 12.306
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 63
  },
  vervielfaeltiger: 12.042
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 64
  },
  vervielfaeltiger: 11.77
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 65
  },
  vervielfaeltiger: 11.49
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 66
  },
  vervielfaeltiger: 11.208
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 67
  },
  vervielfaeltiger: 10.918
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 68
  },
  vervielfaeltiger: 10.621
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 69
  },
  vervielfaeltiger: 10.318
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 70
  },
  vervielfaeltiger: 10.008
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 71
  },
  vervielfaeltiger: 9.691
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 72
  },
  vervielfaeltiger: 9.368
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 73
  },
  vervielfaeltiger: 9.038
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 74
  },
  vervielfaeltiger: 8.696
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 75
  },
  vervielfaeltiger: 8.354
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 76
  },
  vervielfaeltiger: 8.005
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 77
  },
  vervielfaeltiger: 7.651
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 78
  },
  vervielfaeltiger: 7.297
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 79
  },
  vervielfaeltiger: 6.931
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 80
  },
  vervielfaeltiger: 6.567
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 81
  },
  vervielfaeltiger: 6.199
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 82
  },
  vervielfaeltiger: 5.839
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 83
  },
  vervielfaeltiger: 5.484
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 84
  },
  vervielfaeltiger: 5.133
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 85
  },
  vervielfaeltiger: 4.795
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 86
  },
  vervielfaeltiger: 4.472
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 87
  },
  vervielfaeltiger: 4.164
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 88
  },
  vervielfaeltiger: 3.866
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 89
  },
  vervielfaeltiger: 3.593
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 90
  },
  vervielfaeltiger: 3.333
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 91
  },
  vervielfaeltiger: 3.101
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 92
  },
  vervielfaeltiger: 2.882
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 93
  },
  vervielfaeltiger: 2.687
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 94
  },
  vervielfaeltiger: 2.506
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 95
  },
  vervielfaeltiger: 2.34
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 96
  },
  vervielfaeltiger: 2.191
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 97
  },
  vervielfaeltiger: 2.067
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 98
  },
  vervielfaeltiger: 1.942
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 99
  },
  vervielfaeltiger: 1.834
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Mann',
    alter: 100
  },
  vervielfaeltiger: 1.735
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 0
  },
  vervielfaeltiger: 18.467
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 1
  },
  vervielfaeltiger: 18.458
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 2
  },
  vervielfaeltiger: 18.446
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 3
  },
  vervielfaeltiger: 18.433
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 4
  },
  vervielfaeltiger: 18.419
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 5
  },
  vervielfaeltiger: 18.405
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 6
  },
  vervielfaeltiger: 18.39
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 7
  },
  vervielfaeltiger: 18.374
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 8
  },
  vervielfaeltiger: 18.357
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 9
  },
  vervielfaeltiger: 18.339
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 10
  },
  vervielfaeltiger: 18.32
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 11
  },
  vervielfaeltiger: 18.301
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 12
  },
  vervielfaeltiger: 18.28
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 13
  },
  vervielfaeltiger: 18.258
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 14
  },
  vervielfaeltiger: 18.234
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 15
  },
  vervielfaeltiger: 18.21
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 16
  },
  vervielfaeltiger: 18.184
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 17
  },
  vervielfaeltiger: 18.157
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 18
  },
  vervielfaeltiger: 18.129
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 19
  },
  vervielfaeltiger: 18.099
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 20
  },
  vervielfaeltiger: 18.067
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 21
  },
  vervielfaeltiger: 18.033
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 22
  },
  vervielfaeltiger: 17.998
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 23
  },
  vervielfaeltiger: 17.961
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 24
  },
  vervielfaeltiger: 17.922
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 25
  },
  vervielfaeltiger: 17.88
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 26
  },
  vervielfaeltiger: 17.837
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 27
  },
  vervielfaeltiger: 17.791
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 28
  },
  vervielfaeltiger: 17.742
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 29
  },
  vervielfaeltiger: 17.691
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 30
  },
  vervielfaeltiger: 17.638
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 31
  },
  vervielfaeltiger: 17.581
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 32
  },
  vervielfaeltiger: 17.521
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 33
  },
  vervielfaeltiger: 17.458
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 34
  },
  vervielfaeltiger: 17.392
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 35
  },
  vervielfaeltiger: 17.323
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 36
  },
  vervielfaeltiger: 17.25
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 37
  },
  vervielfaeltiger: 17.173
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 38
  },
  vervielfaeltiger: 17.092
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 39
  },
  vervielfaeltiger: 17.007
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 40
  },
  vervielfaeltiger: 16.917
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 41
  },
  vervielfaeltiger: 16.823
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 42
  },
  vervielfaeltiger: 16.724
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 43
  },
  vervielfaeltiger: 16.621
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 44
  },
  vervielfaeltiger: 16.511
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 45
  },
  vervielfaeltiger: 16.396
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 46
  },
  vervielfaeltiger: 16.276
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 47
  },
  vervielfaeltiger: 16.15
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 48
  },
  vervielfaeltiger: 16.018
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 49
  },
  vervielfaeltiger: 15.879
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 50
  },
  vervielfaeltiger: 15.734
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 51
  },
  vervielfaeltiger: 15.582
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 52
  },
  vervielfaeltiger: 15.424
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 53
  },
  vervielfaeltiger: 15.258
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 54
  },
  vervielfaeltiger: 15.085
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 55
  },
  vervielfaeltiger: 14.903
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 56
  },
  vervielfaeltiger: 14.715
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 57
  },
  vervielfaeltiger: 14.519
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 58
  },
  vervielfaeltiger: 14.313
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 59
  },
  vervielfaeltiger: 14.1
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 60
  },
  vervielfaeltiger: 13.879
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 61
  },
  vervielfaeltiger: 13.65
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 62
  },
  vervielfaeltiger: 13.413
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 63
  },
  vervielfaeltiger: 13.164
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 64
  },
  vervielfaeltiger: 12.908
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 65
  },
  vervielfaeltiger: 12.642
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 66
  },
  vervielfaeltiger: 12.368
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 67
  },
  vervielfaeltiger: 12.081
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 68
  },
  vervielfaeltiger: 11.788
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 69
  },
  vervielfaeltiger: 11.482
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 70
  },
  vervielfaeltiger: 11.167
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 71
  },
  vervielfaeltiger: 10.843
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 72
  },
  vervielfaeltiger: 10.508
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 73
  },
  vervielfaeltiger: 10.169
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 74
  },
  vervielfaeltiger: 9.815
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 75
  },
  vervielfaeltiger: 9.452
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 76
  },
  vervielfaeltiger: 9.084
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 77
  },
  vervielfaeltiger: 8.702
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 78
  },
  vervielfaeltiger: 8.315
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 79
  },
  vervielfaeltiger: 7.913
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 80
  },
  vervielfaeltiger: 7.514
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 81
  },
  vervielfaeltiger: 7.112
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 82
  },
  vervielfaeltiger: 6.703
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 83
  },
  vervielfaeltiger: 6.298
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 84
  },
  vervielfaeltiger: 5.908
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 85
  },
  vervielfaeltiger: 5.519
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 86
  },
  vervielfaeltiger: 5.147
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 87
  },
  vervielfaeltiger: 4.795
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 88
  },
  vervielfaeltiger: 4.449
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 89
  },
  vervielfaeltiger: 4.125
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 90
  },
  vervielfaeltiger: 3.826
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 91
  },
  vervielfaeltiger: 3.545
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 92
  },
  vervielfaeltiger: 3.283
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 93
  },
  vervielfaeltiger: 3.051
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 94
  },
  vervielfaeltiger: 2.832
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 95
  },
  vervielfaeltiger: 2.635
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 96
  },
  vervielfaeltiger: 2.454
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 97
  },
  vervielfaeltiger: 2.297
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 98
  },
  vervielfaeltiger: 2.165
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 99
  },
  vervielfaeltiger: 2.049
}, {
  key: {
    jahr: 2023,
    geschlecht: 'Frau',
    alter: 100
  },
  vervielfaeltiger: 1.924
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 0
  },
  vervielfaeltiger: 18.4
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 1
  },
  vervielfaeltiger: 18.388
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 2
  },
  vervielfaeltiger: 18.373
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 3
  },
  vervielfaeltiger: 18.356
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 4
  },
  vervielfaeltiger: 18.338
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 5
  },
  vervielfaeltiger: 18.319
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 6
  },
  vervielfaeltiger: 18.299
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 7
  },
  vervielfaeltiger: 18.279
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 8
  },
  vervielfaeltiger: 18.256
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 9
  },
  vervielfaeltiger: 18.233
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 10
  },
  vervielfaeltiger: 18.209
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 11
  },
  vervielfaeltiger: 18.183
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 12
  },
  vervielfaeltiger: 18.155
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 13
  },
  vervielfaeltiger: 18.127
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 14
  },
  vervielfaeltiger: 18.096
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 15
  },
  vervielfaeltiger: 18.064
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 16
  },
  vervielfaeltiger: 18.031
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 17
  },
  vervielfaeltiger: 17.995
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 18
  },
  vervielfaeltiger: 17.958
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 19
  },
  vervielfaeltiger: 17.919
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 20
  },
  vervielfaeltiger: 17.879
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 21
  },
  vervielfaeltiger: 17.835
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 22
  },
  vervielfaeltiger: 17.79
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 23
  },
  vervielfaeltiger: 17.42
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 24
  },
  vervielfaeltiger: 17.692
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 25
  },
  vervielfaeltiger: 17.639
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 26
  },
  vervielfaeltiger: 17.582
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 27
  },
  vervielfaeltiger: 17.524
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 28
  },
  vervielfaeltiger: 17.461
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 29
  },
  vervielfaeltiger: 17.396
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 30
  },
  vervielfaeltiger: 17.327
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 31
  },
  vervielfaeltiger: 17.255
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 32
  },
  vervielfaeltiger: 17.178
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 33
  },
  vervielfaeltiger: 17.098
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 34
  },
  vervielfaeltiger: 17.014
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 35
  },
  vervielfaeltiger: 16.925
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 36
  },
  vervielfaeltiger: 16.832
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 37
  },
  vervielfaeltiger: 16.734
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 38
  },
  vervielfaeltiger: 16.632
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 39
  },
  vervielfaeltiger: 16.525
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 40
  },
  vervielfaeltiger: 16.412
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 41
  },
  vervielfaeltiger: 16.294
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 42
  },
  vervielfaeltiger: 16.169
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 43
  },
  vervielfaeltiger: 16.039
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 44
  },
  vervielfaeltiger: 15.903
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 45
  },
  vervielfaeltiger: 15.76
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 46
  },
  vervielfaeltiger: 15.61
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 47
  },
  vervielfaeltiger: 15.454
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 48
  },
  vervielfaeltiger: 15.291
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 49
  },
  vervielfaeltiger: 15.119
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 50
  },
  vervielfaeltiger: 14.943
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 51
  },
  vervielfaeltiger: 14.759
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 52
  },
  vervielfaeltiger: 14.567
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 53
  },
  vervielfaeltiger: 14.367
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 54
  },
  vervielfaeltiger: 14.158
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 55
  },
  vervielfaeltiger: 13.943
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 56
  },
  vervielfaeltiger: 13.722
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 57
  },
  vervielfaeltiger: 13.491
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 58
  },
  vervielfaeltiger: 13.255
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 59
  },
  vervielfaeltiger: 13.012
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 60
  },
  vervielfaeltiger: 12.76
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 61
  },
  vervielfaeltiger: 12.505
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 62
  },
  vervielfaeltiger: 12.241
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 63
  },
  vervielfaeltiger: 11.97
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 64
  },
  vervielfaeltiger: 11.695
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 65
  },
  vervielfaeltiger: 11.413
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 66
  },
  vervielfaeltiger: 11.127
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 67
  },
  vervielfaeltiger: 10.834
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 68
  },
  vervielfaeltiger: 10.539
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 69
  },
  vervielfaeltiger: 10.233
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 70
  },
  vervielfaeltiger: 9.924
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 71
  },
  vervielfaeltiger: 9.604
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 72
  },
  vervielfaeltiger: 9.283
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 73
  },
  vervielfaeltiger: 8.95
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 74
  },
  vervielfaeltiger: 8.616
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 75
  },
  vervielfaeltiger: 8.271
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 76
  },
  vervielfaeltiger: 7.931
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 77
  },
  vervielfaeltiger: 7.574
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 78
  },
  vervielfaeltiger: 7.217
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 79
  },
  vervielfaeltiger: 6.856
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 80
  },
  vervielfaeltiger: 6.489
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 81
  },
  vervielfaeltiger: 6.132
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 82
  },
  vervielfaeltiger: 5.77
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 83
  },
  vervielfaeltiger: 5.413
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 84
  },
  vervielfaeltiger: 5.06
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 85
  },
  vervielfaeltiger: 4.713
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 86
  },
  vervielfaeltiger: 4.388
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 87
  },
  vervielfaeltiger: 4.07
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 88
  },
  vervielfaeltiger: 3.778
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 89
  },
  vervielfaeltiger: 3.496
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 90
  },
  vervielfaeltiger: 3.242
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 91
  },
  vervielfaeltiger: 3
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 92
  },
  vervielfaeltiger: 2.772
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 93
  },
  vervielfaeltiger: 2.575
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 94
  },
  vervielfaeltiger: 2.393
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 95
  },
  vervielfaeltiger: 2.235
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 96
  },
  vervielfaeltiger: 2.094
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 97
  },
  vervielfaeltiger: 1.996
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 98
  },
  vervielfaeltiger: 1.888
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 99
  },
  vervielfaeltiger: 1.78
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Mann',
    alter: 100
  },
  vervielfaeltiger: 1.671
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 0
  },
  vervielfaeltiger: 18.464
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 1
  },
  vervielfaeltiger: 18.455
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 2
  },
  vervielfaeltiger: 18.443
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 3
  },
  vervielfaeltiger: 18.43
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 4
  },
  vervielfaeltiger: 18.416
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 5
  },
  vervielfaeltiger: 18.402
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 6
  },
  vervielfaeltiger: 18.387
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 7
  },
  vervielfaeltiger: 18.37
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 8
  },
  vervielfaeltiger: 18.353
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 9
  },
  vervielfaeltiger: 18.335
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 10
  },
  vervielfaeltiger: 18.316
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 11
  },
  vervielfaeltiger: 18.296
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 12
  },
  vervielfaeltiger: 18.275
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 13
  },
  vervielfaeltiger: 18.253
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 14
  },
  vervielfaeltiger: 18.23
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 15
  },
  vervielfaeltiger: 18.205
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 16
  },
  vervielfaeltiger: 18.179
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 17
  },
  vervielfaeltiger: 18.152
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 18
  },
  vervielfaeltiger: 18.123
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 19
  },
  vervielfaeltiger: 18.092
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 20
  },
  vervielfaeltiger: 18.06
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 21
  },
  vervielfaeltiger: 18.026
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 22
  },
  vervielfaeltiger: 17.991
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 23
  },
  vervielfaeltiger: 17.953
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 24
  },
  vervielfaeltiger: 17.913
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 25
  },
  vervielfaeltiger: 17.872
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 26
  },
  vervielfaeltiger: 17.828
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 27
  },
  vervielfaeltiger: 17.781
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 28
  },
  vervielfaeltiger: 17.732
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 29
  },
  vervielfaeltiger: 17.681
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 30
  },
  vervielfaeltiger: 17.626
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 31
  },
  vervielfaeltiger: 17.569
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 32
  },
  vervielfaeltiger: 17.509
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 33
  },
  vervielfaeltiger: 17.446
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 34
  },
  vervielfaeltiger: 17.379
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 35
  },
  vervielfaeltiger: 17.309
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 36
  },
  vervielfaeltiger: 17.235
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 37
  },
  vervielfaeltiger: 17.157
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 38
  },
  vervielfaeltiger: 17.075
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 39
  },
  vervielfaeltiger: 16.989
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 40
  },
  vervielfaeltiger: 16.899
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 41
  },
  vervielfaeltiger: 16.804
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 42
  },
  vervielfaeltiger: 16.704
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 43
  },
  vervielfaeltiger: 16.6
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 44
  },
  vervielfaeltiger: 16.489
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 45
  },
  vervielfaeltiger: 16.373
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 46
  },
  vervielfaeltiger: 16.251
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 47
  },
  vervielfaeltiger: 16.124
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 48
  },
  vervielfaeltiger: 15.991
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 49
  },
  vervielfaeltiger: 15.85
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 50
  },
  vervielfaeltiger: 15.704
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 51
  },
  vervielfaeltiger: 15.552
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 52
  },
  vervielfaeltiger: 15.391
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 53
  },
  vervielfaeltiger: 15.223
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 54
  },
  vervielfaeltiger: 15.048
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 55
  },
  vervielfaeltiger: 14.865
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 56
  },
  vervielfaeltiger: 14.674
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 57
  },
  vervielfaeltiger: 14.476
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 58
  },
  vervielfaeltiger: 14.268
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 59
  },
  vervielfaeltiger: 14.053
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 60
  },
  vervielfaeltiger: 13.83
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 61
  },
  vervielfaeltiger: 13.596
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 62
  },
  vervielfaeltiger: 13.356
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 63
  },
  vervielfaeltiger: 13.105
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 64
  },
  vervielfaeltiger: 12.849
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 65
  },
  vervielfaeltiger: 12.58
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 66
  },
  vervielfaeltiger: 12.303
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 67
  },
  vervielfaeltiger: 12.017
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 68
  },
  vervielfaeltiger: 11.718
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 69
  },
  vervielfaeltiger: 11.413
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 70
  },
  vervielfaeltiger: 11.099
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 71
  },
  vervielfaeltiger: 10.771
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 72
  },
  vervielfaeltiger: 10.438
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 73
  },
  vervielfaeltiger: 10.091
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 74
  },
  vervielfaeltiger: 9.739
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 75
  },
  vervielfaeltiger: 9.373
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 76
  },
  vervielfaeltiger: 9.002
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 77
  },
  vervielfaeltiger: 8.621
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 78
  },
  vervielfaeltiger: 8.237
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 79
  },
  vervielfaeltiger: 7.844
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 80
  },
  vervielfaeltiger: 7.442
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 81
  },
  vervielfaeltiger: 7.038
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 82
  },
  vervielfaeltiger: 6.632
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 83
  },
  vervielfaeltiger: 6.232
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 84
  },
  vervielfaeltiger: 5.832
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 85
  },
  vervielfaeltiger: 5.441
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 86
  },
  vervielfaeltiger: 5.06
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 87
  },
  vervielfaeltiger: 4.706
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 88
  },
  vervielfaeltiger: 4.365
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 89
  },
  vervielfaeltiger: 4.031
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 90
  },
  vervielfaeltiger: 3.73
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 91
  },
  vervielfaeltiger: 3.447
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 92
  },
  vervielfaeltiger: 3.184
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 93
  },
  vervielfaeltiger: 2.95
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 94
  },
  vervielfaeltiger: 2.729
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 95
  },
  vervielfaeltiger: 2.532
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 96
  },
  vervielfaeltiger: 2.358
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 97
  },
  vervielfaeltiger: 2.209
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 98
  },
  vervielfaeltiger: 2.076
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 99
  },
  vervielfaeltiger: 1.969
}, {
  key: {
    jahr: 2024,
    geschlecht: 'Frau',
    alter: 100
  },
  vervielfaeltiger: 1.87
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 0
  },
  vervielfaeltiger: 18.398
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 1
  },
  vervielfaeltiger: 18.386
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 2
  },
  vervielfaeltiger: 18.37
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 3
  },
  vervielfaeltiger: 18.353
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 4
  },
  vervielfaeltiger: 18.335
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 5
  },
  vervielfaeltiger: 18.316
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 6
  },
  vervielfaeltiger: 18.296
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 7
  },
  vervielfaeltiger: 18.275
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 8
  },
  vervielfaeltiger: 18.253
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 9
  },
  vervielfaeltiger: 18.23
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 10
  },
  vervielfaeltiger: 18.205
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 11
  },
  vervielfaeltiger: 18.179
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 12
  },
  vervielfaeltiger: 18.151
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 13
  },
  vervielfaeltiger: 18.122
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 14
  },
  vervielfaeltiger: 18.092
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 15
  },
  vervielfaeltiger: 18.06
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 16
  },
  vervielfaeltiger: 18.026
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 17
  },
  vervielfaeltiger: 17.99
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 18
  },
  vervielfaeltiger: 17.953
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 19
  },
  vervielfaeltiger: 17.913
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 20
  },
  vervielfaeltiger: 17.872
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 21
  },
  vervielfaeltiger: 17.829
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 22
  },
  vervielfaeltiger: 17.783
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 23
  },
  vervielfaeltiger: 17.736
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 24
  },
  vervielfaeltiger: 17.685
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 25
  },
  vervielfaeltiger: 17.631
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 26
  },
  vervielfaeltiger: 17.575
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 27
  },
  vervielfaeltiger: 17.516
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 28
  },
  vervielfaeltiger: 17.453
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 29
  },
  vervielfaeltiger: 17.387
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 30
  },
  vervielfaeltiger: 17.318
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 31
  },
  vervielfaeltiger: 17.245
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 32
  },
  vervielfaeltiger: 17.168
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 33
  },
  vervielfaeltiger: 17.087
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 34
  },
  vervielfaeltiger: 17.002
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 35
  },
  vervielfaeltiger: 16.913
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 36
  },
  vervielfaeltiger: 16.819
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 37
  },
  vervielfaeltiger: 16.721
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 38
  },
  vervielfaeltiger: 16.618
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 39
  },
  vervielfaeltiger: 16.51
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 40
  },
  vervielfaeltiger: 16.396
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 41
  },
  vervielfaeltiger: 16.277
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 42
  },
  vervielfaeltiger: 16.152
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 43
  },
  vervielfaeltiger: 16.021
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 44
  },
  vervielfaeltiger: 15.884
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 45
  },
  vervielfaeltiger: 15.741
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 46
  },
  vervielfaeltiger: 15.591
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 47
  },
  vervielfaeltiger: 15.433
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 48
  },
  vervielfaeltiger: 15.269
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 49
  },
  vervielfaeltiger: 15.098
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 50
  },
  vervielfaeltiger: 14.919
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 51
  },
  vervielfaeltiger: 14.734
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 52
  },
  vervielfaeltiger: 14.541
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 53
  },
  vervielfaeltiger: 14.339
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 54
  },
  vervielfaeltiger: 14.129
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 55
  },
  vervielfaeltiger: 13.915
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 56
  },
  vervielfaeltiger: 13.69
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 57
  },
  vervielfaeltiger: 13.458
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 58
  },
  vervielfaeltiger: 13.22
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 59
  },
  vervielfaeltiger: 12.972
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 60
  },
  vervielfaeltiger: 12.722
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 61
  },
  vervielfaeltiger: 12.461
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 62
  },
  vervielfaeltiger: 12.196
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 63
  },
  vervielfaeltiger: 11.923
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 64
  },
  vervielfaeltiger: 11.646
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 65
  },
  vervielfaeltiger: 11.362
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 66
  },
  vervielfaeltiger: 11.074
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 67
  },
  vervielfaeltiger: 10.78
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 68
  },
  vervielfaeltiger: 10.478
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 69
  },
  vervielfaeltiger: 10.169
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 70
  },
  vervielfaeltiger: 9.858
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 71
  },
  vervielfaeltiger: 9.536
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 72
  },
  vervielfaeltiger: 9.212
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 73
  },
  vervielfaeltiger: 8.882
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 74
  },
  vervielfaeltiger: 8.54
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 75
  },
  vervielfaeltiger: 8.198
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 76
  },
  vervielfaeltiger: 7.856
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 77
  },
  vervielfaeltiger: 7.502
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 78
  },
  vervielfaeltiger: 7.15
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 79
  },
  vervielfaeltiger: 6.786
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 80
  },
  vervielfaeltiger: 6.43
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 81
  },
  vervielfaeltiger: 6.064
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 82
  },
  vervielfaeltiger: 5.708
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 83
  },
  vervielfaeltiger: 5.349
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 84
  },
  vervielfaeltiger: 5.002
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 85
  },
  vervielfaeltiger: 4.661
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 86
  },
  vervielfaeltiger: 4.334
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 87
  },
  vervielfaeltiger: 4.016
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 88
  },
  vervielfaeltiger: 3.722
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 89
  },
  vervielfaeltiger: 3.447
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 90
  },
  vervielfaeltiger: 3.192
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 91
  },
  vervielfaeltiger: 2.958
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 92
  },
  vervielfaeltiger: 2.747
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 93
  },
  vervielfaeltiger: 2.549
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 94
  },
  vervielfaeltiger: 2.375
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 95
  },
  vervielfaeltiger: 2.209
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 96
  },
  vervielfaeltiger: 2.067
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 97
  },
  vervielfaeltiger: 1.942
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 98
  },
  vervielfaeltiger: 1.825
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 99
  },
  vervielfaeltiger: 1.725
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Mann',
    alter: 100
  },
  vervielfaeltiger: 1.634
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 0
  },
  vervielfaeltiger: 18.462
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 1
  },
  vervielfaeltiger: 18.453
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 2
  },
  vervielfaeltiger: 18.441
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 3
  },
  vervielfaeltiger: 18.428
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 4
  },
  vervielfaeltiger: 18.414
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 5
  },
  vervielfaeltiger: 18.399
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 6
  },
  vervielfaeltiger: 18.384
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 7
  },
  vervielfaeltiger: 18.367
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 8
  },
  vervielfaeltiger: 18.35
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 9
  },
  vervielfaeltiger: 18.332
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 10
  },
  vervielfaeltiger: 18.313
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 11
  },
  vervielfaeltiger: 18.293
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 12
  },
  vervielfaeltiger: 18.272
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 13
  },
  vervielfaeltiger: 18.249
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 14
  },
  vervielfaeltiger: 18.225
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 15
  },
  vervielfaeltiger: 18.201
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 16
  },
  vervielfaeltiger: 18.174
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 17
  },
  vervielfaeltiger: 18.147
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 18
  },
  vervielfaeltiger: 18.118
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 19
  },
  vervielfaeltiger: 18.087
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 20
  },
  vervielfaeltiger: 18.055
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 21
  },
  vervielfaeltiger: 18.021
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 22
  },
  vervielfaeltiger: 17.985
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 23
  },
  vervielfaeltiger: 17.947
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 24
  },
  vervielfaeltiger: 17.907
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 25
  },
  vervielfaeltiger: 17.865
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 26
  },
  vervielfaeltiger: 17.82
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 27
  },
  vervielfaeltiger: 17.774
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 28
  },
  vervielfaeltiger: 17.724
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 29
  },
  vervielfaeltiger: 17.672
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 30
  },
  vervielfaeltiger: 17.617
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 31
  },
  vervielfaeltiger: 17.56
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 32
  },
  vervielfaeltiger: 17.499
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 33
  },
  vervielfaeltiger: 17.435
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 34
  },
  vervielfaeltiger: 17.368
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 35
  },
  vervielfaeltiger: 17.297
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 36
  },
  vervielfaeltiger: 17.223
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 37
  },
  vervielfaeltiger: 17.144
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 38
  },
  vervielfaeltiger: 17.062
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 39
  },
  vervielfaeltiger: 16.976
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 40
  },
  vervielfaeltiger: 16.885
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 41
  },
  vervielfaeltiger: 16.789
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 42
  },
  vervielfaeltiger: 16.688
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 43
  },
  vervielfaeltiger: 16.582
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 44
  },
  vervielfaeltiger: 16.471
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 45
  },
  vervielfaeltiger: 16.354
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 46
  },
  vervielfaeltiger: 16.233
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 47
  },
  vervielfaeltiger: 16.104
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 48
  },
  vervielfaeltiger: 15.969
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 49
  },
  vervielfaeltiger: 15.829
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 50
  },
  vervielfaeltiger: 15.68
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 51
  },
  vervielfaeltiger: 15.527
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 52
  },
  vervielfaeltiger: 15.364
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 53
  },
  vervielfaeltiger: 15.195
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 54
  },
  vervielfaeltiger: 15.019
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 55
  },
  vervielfaeltiger: 14.834
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 56
  },
  vervielfaeltiger: 14.642
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 57
  },
  vervielfaeltiger: 14.442
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 58
  },
  vervielfaeltiger: 14.233
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 59
  },
  vervielfaeltiger: 14.016
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 60
  },
  vervielfaeltiger: 13.791
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 61
  },
  vervielfaeltiger: 13.555
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 62
  },
  vervielfaeltiger: 13.313
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 63
  },
  vervielfaeltiger: 13.063
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 64
  },
  vervielfaeltiger: 12.802
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 65
  },
  vervielfaeltiger: 12.534
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 66
  },
  vervielfaeltiger: 12.255
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 67
  },
  vervielfaeltiger: 11.97
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 68
  },
  vervielfaeltiger: 11.673
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 69
  },
  vervielfaeltiger: 11.366
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 70
  },
  vervielfaeltiger: 11.05
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 71
  },
  vervielfaeltiger: 10.724
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 72
  },
  vervielfaeltiger: 10.389
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 73
  },
  vervielfaeltiger: 10.045
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 74
  },
  vervielfaeltiger: 9.691
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 75
  },
  vervielfaeltiger: 9.333
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 76
  },
  vervielfaeltiger: 8.965
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 77
  },
  vervielfaeltiger: 8.589
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 78
  },
  vervielfaeltiger: 8.203
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 79
  },
  vervielfaeltiger: 7.815
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 80
  },
  vervielfaeltiger: 7.424
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 81
  },
  vervielfaeltiger: 7.025
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 82
  },
  vervielfaeltiger: 6.626
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 83
  },
  vervielfaeltiger: 6.225
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 84
  },
  vervielfaeltiger: 5.825
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 85
  },
  vervielfaeltiger: 5.434
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 86
  },
  vervielfaeltiger: 5.06
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 87
  },
  vervielfaeltiger: 4.698
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 88
  },
  vervielfaeltiger: 4.349
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 89
  },
  vervielfaeltiger: 4.031
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 90
  },
  vervielfaeltiger: 3.722
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 91
  },
  vervielfaeltiger: 3.447
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 92
  },
  vervielfaeltiger: 3.184
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 93
  },
  vervielfaeltiger: 2.95
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 94
  },
  vervielfaeltiger: 2.738
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 95
  },
  vervielfaeltiger: 2.54
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 96
  },
  vervielfaeltiger: 2.367
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 97
  },
  vervielfaeltiger: 2.2
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 98
  },
  vervielfaeltiger: 2.058
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 99
  },
  vervielfaeltiger: 1.924
}, {
  key: {
    jahr: 2025,
    geschlecht: 'Frau',
    alter: 100
  },
  vervielfaeltiger: 1.816
}];
/*
————————————————————————————————————————————————————————————————————————————————
Exportierte Konstanten, Typen und Arrays
————————————————————————————————————————————————————————————————————————————————
*/
// Fehlermeldungen
exports.errorMsgKeineQuelle = 'Keine Quellangaben vorhanden';
exports.errorMsgUngueltigesJahr = 'Kein oder ungültiges Jahr angegeben';
exports.errorMsgUngueltigesGeschlecht = 'Kein oder ungültiges Geschlecht angegeben';
exports.errorMsgUngueltigesAlter = 'Kein oder ungültiges Alter angegeben';
exports.errorMsgKeineVervielfaeltigerFuerJahr = 'Keine Vervielfältiger-Daten für Jahr vorhanden';
/*
————————————————————————————————————————————————————————————————————————————————
Exportierte Funktionen
————————————————————————————————————————————————————————————————————————————————
*/
// Rückgabe der verfügbaren Jahresscheiben für die Vervielfältiger-Werte
function listeJahreszahlenVervielfaeltiger() {
  const liste = TabelleVervielfaeltiger.map(element => element.key.jahr);
  // Duplikate entfernen
  return Array.from(new Set(liste));
}
// Rückgabe der standardmäßig verwendeten Jahresscheibe für die Vervielfältiger-Werte
function ermittleVerwendetesJahr() {
  return Math.max(...TabelleVervielfaeltigerQuellen.map(element => element.jahr));
  // Alternative: Rechnung immer für aktuelles Jahr
  //return new Date().getFullYear();
}
// Rückgabe der Quelleninformation zu den Vervielfältiger-Daten für ein gegegebenes Jahr
function ermittleVerwendetesJahrZusatzdaten(inputJahr) {
  const tabellenEintrag = TabelleVervielfaeltigerQuellen.find(element => element.jahr === inputJahr);
  if (tabellenEintrag === undefined) {
    throw Error(exports.errorMsgKeineQuelle);
  } else {
    return tabellenEintrag;
  }
}
// Ermittlung Vervielfältiger für gegebene Kombination aus Jahr, Geschlecht und Alter
function ermittleVervielfaeltiger(inputJahr, inputGeschlecht, inputAlter) {
  // Prüfung Parameter
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (inputJahr === undefined || typeof inputJahr !== 'number') {
    throw Error(exports.errorMsgUngueltigesJahr);
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (inputGeschlecht === undefined || !(0, rechner_1.isGeschlecht)(inputGeschlecht)) {
    throw Error(exports.errorMsgUngueltigesGeschlecht);
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (inputAlter === undefined || typeof inputAlter !== 'number') {
    throw Error(exports.errorMsgUngueltigesAlter);
  }
  if (inputAlter < rechner_1.Rechner.minAlter || inputAlter > rechner_1.Rechner.maxAlter) {
    throw Error(exports.errorMsgUngueltigesAlter);
  }
  if (!listeJahreszahlenVervielfaeltiger().includes(inputJahr)) {
    throw Error(exports.errorMsgKeineVervielfaeltigerFuerJahr);
  }
  // Die eigentliche Logik für den Lookup wurde der Lesbarkeit in eine separate Funktion ausgelagert
  return lookupVervielfaeltiger({
    jahr: inputJahr,
    geschlecht: inputGeschlecht,
    // Limitierung bei 100 Jahren (siehe BMF-Schreiben, Tabelleneintrag dort lautet "100 und darüber")
    alter: inputAlter > 100 ? 100 : inputAlter
  });
}
/*
————————————————————————————————————————————————————————————————————————————————
Interne Funktionen
————————————————————————————————————————————————————————————————————————————————
*/
// Ermittlung Vervielfältiger
function lookupVervielfaeltiger(kenngroessen) {
  const tabellenEintrag = TabelleVervielfaeltiger.find(element => vergleicheVervielfaeltigerKey(kenngroessen, element.key));
  if (tabellenEintrag === undefined) {
    throw Error(errorMsgKeinVervielfaeltiger);
  } else {
    return tabellenEintrag.vervielfaeltiger;
  }
}
// Benutzerdefinierte Funktion zum Vergleich zweier Parametersätze ("deep comparison")
function vergleicheVervielfaeltigerKey(vervielfaeltiger1, vervielfaeltiger2) {
  return vervielfaeltiger1.jahr === vervielfaeltiger2.jahr && vervielfaeltiger1.geschlecht === vervielfaeltiger2.geschlecht && vervielfaeltiger1.alter === vervielfaeltiger2.alter;
}
