<div class="container">
  <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div class="flex justify-content-between align-items-center">
      <!-- Unsichtbarer Dummy-Button zur korrekten Positionierung der beiden folgenden Elemente (eines zentriert, eines rechts) -->
      <button pButton label="Drucken" style="visibility: hidden"></button>
      <h2 class="mx-auto">Ihre Angaben</h2>
      <button
        pButton
        label="Drucken"
        icon="icomoon-nbr-print"
        iconPos="left"
        class="p-button-secondary p-button-sm"
        onClick="window.print()"
      ></button>
    </div>
    <div class="grid mb-4 align-items-stretch">
      <div class="col-12 xl:col-4">
        <div class="card-like">
          <h3>Sie als Schenkender</h3>
          <div>
            <h4>
              <i class="icomoon-nbr-alter" aria-hidden="true"></i> Ihr Alter:
              {{ this.formGroup.controls.alterFormControl.value }}&nbsp;
              <info-toggle #infoAlterToggle></info-toggle>
            </h4>

            <div>
              <div class="col">
                <p-slider
                  formControlName="alterFormControl"
                  [min]="minAlterAppComponent"
                  [max]="maxAlterAppComponent"
                >
                </p-slider>
              </div>
              <div
                *ngIf="
                  this.formGroup.controls.alterFormControl.invalid &&
                  this.formGroup.controls.alterFormControl.dirty
                "
                class="p-fluid col-12 error-msg"
              >
                Angabe des Alters ist erforderlich!
              </div>
            </div>
            <div *ngIf="infoAlterToggle.isOn">
              Geben Sie bitte Ihr vollendetes Lebensalter an.<br />
              Umso jünger die Schenkende/der Schenkende ist, desto höher ist der
              Kapitalwert des Nießbrauchs und damit der finanzielle Spielraum
              für einen steuerschonenden Übertrag.
            </div>
          </div>
          <div>
            <h4>
              <i class="icomoon-nbr-geschlecht"  aria-hidden="true"></i> Ihr Geschlecht:&nbsp;
              <info-toggle #infoGeschlechtToggle></info-toggle>
            </h4>
            <div>
              <div class="col">
                <p-selectButton
                  class="p-fluid"
                  [options]="geschlechter"
                  formControlName="geschlechtFormControl"
                  optionValue="geschlecht"
                  optionLabel="label"
                ></p-selectButton>
              </div>
              <div
                *ngIf="
                  this.formGroup.controls.geschlechtFormControl.invalid &&
                  this.formGroup.controls.geschlechtFormControl.dirty
                "
                class="p-fluid col-12 error-msg"
              >
                Angabe des Geschlechts ist erforderlich!
              </div>
            </div>
            <div *ngIf="infoGeschlechtToggle.isOn">
              Geben Sie bitte Ihr Geschlecht ein.<br />
              Der Kapitalwert des Nießbrauchs ist auch abhängig davon, ob Sie
              eine Frau oder ein Mann sind.
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 xl:col-4">
        <div class="card-like">
          <h3>Diese Person wollen Sie beschenken</h3>
          <div>
            <h4>
              <i class="icomoon-nbr-verhaeltnis" aria-hidden="true"></i> Ihr Beziehungsverhältnis:&nbsp;
              <info-toggle #infoBeziehungToggle></info-toggle>
            </h4>
            <div>
              <div class="col">
                <p-dropdown
                  class="p-fluid"
                  [options]="beziehungen"
                  formControlName="beziehungFormControl"
                  placeholder="Beziehungsverhältnis auswählen"
                  optionValue="beziehung"
                  optionLabel="label"
                  dropdownIcon="icomoon-nbr-arrow-down"
                ></p-dropdown>
              </div>
              <div
                *ngIf="
                  this.formGroup.controls.beziehungFormControl.invalid &&
                  this.formGroup.controls.beziehungFormControl.dirty
                "
                class="p-fluid p-col-12 error-msg"
              >
                Angabe des Beziehungsverhältnisses ist erforderlich!
              </div>
            </div>
            <div *ngIf="infoBeziehungToggle.isOn">
              Geben Sie bitte Ihr Beziehungsverhältnis zur Beschenkten/zum
              Beschenkten ein.<br />
              Dieses legt den Freibetrag der Schenkungssteuer fest.
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 xl:col-4">
        <div class="card-like">
          <h3>Durchschnittliche Wertentwicklung Ihres Depots</h3>
          <div>
            <h4>
              <i class="icomoon-nbr-jahresrendite" aria-hidden="true"></i> Angenommene Jahresrendite:
              {{
                this.formGroup.controls.zinssatzFormControl.value
                  | number: "1.2-2":"de-DE"
              }}{{
                this.formGroup.controls.zinssatzFormControl.value ? "%" : ""
              }}
              &nbsp; <info-toggle #infoZinssatzToggle></info-toggle>
            </h4>
            <div>
              <div class="col">
                <p-slider
                  formControlName="zinssatzFormControl"
                  [min]="minZinssatzAppComponent"
                  [max]="maxZinssatzAppComponent"
                  [step]="0.01"
                ></p-slider>
              </div>
              <div
                *ngIf="
                  this.formGroup.controls.zinssatzFormControl.invalid &&
                  this.formGroup.controls.zinssatzFormControl.dirty
                "
                class="p-fluid p-col-12 error-msg"
              >
                Angabe des Zinssatzes ist erforderlich!
              </div>
            </div>
            <div *ngIf="infoZinssatzToggle.isOn">
              Bei einem Nießbrauch können grundsätzlich ordentliche (Dividenden‑
              und Zinseinnahmen) und außerordentliche Erträge (entstehen etwa
              durch den Verkauf von Wertpapieren) berücksichtigt werden.<br />
              Unstrittig ist die Anerkennung der ordentlichen Erträge. Die
              durchschnittliche Dividendenrendite im Deutschen Aktienindex (DAX)
              lag in den vergangenen Jahren bei rund 3% pro Jahr. Die Renditen
              von Anleihen lagen im gleichen Zeitraum deutlich darunter. Wer im
              Jahr 2014 in den DAX investiert und 2018 wieder verkauft hat,
              hätte nach dem Renditedreieck des Deutschen Aktieninstitutes (DAI)
              eine durchschnittliche Jahresrendite von 1,9% erzielt. Von 2005
              bis 2018 wären es 5,3% p. a. gewesen.<br />
              Von daher sollten Sie sich hier unbedingt mit Ihrem
              Vermögensverwalter hinsichtlich Ihrer Anlagestrategie und Ihrem
              Steuerberater hinsichtlich der Anerkennungsfähigkeit
              besprechen.<br />
              Das Finanzamt selbst erkennt jährliche Renditen von maximal
              {{ maxZinssatzAppComponent | number: "1.2-2":"de-DE" }}% bei
              entsprechenden Berechnungen an. Höhere Renditen werden dagegen
              gekappt und nicht berücksichtigt.<br />
              Der minimale Eingabewert beträgt
              {{ minZinssatzAppComponent | number: "1.2-2":"de-DE" }}%.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="grid">
      <div *ngIf="rechnerInitial" class="col-4 col-offset-4">
        <p-button
          class="p-fluid"
          type="submit"
          label="Jetzt berechnen"
        ></p-button>
      </div>
    </div>
  </form>

  <div *ngIf="ergebnis">
    <h2 class="result">Ergebnis</h2>
    <div class="grid justify-content-around">
      <div class="col-12 xl:col-6">
        <div class="card-like">
          <h3>Ihr möglicher Schenkungsbetrag</h3>
          <div class="result">
            {{
              ergebnis!.steuerfreierBetrag
                | currency: "EUR":"symbol":"1.0-0":"de-DE"
            }}
          </div>
          <p>
          <p>
            Nach Berücksichtigung des Kapitalwertes des Nießbrauches sowie des persönlichen Schenkungsfreibetrages
            beträgt die Bemessungsgrundlage für die Schenkungsteuer bei einer Schenkung an Ihre/Ihren
            {{ this.gewaehlterVerwandschaftsgrad }} 0,- Euro.
          </p>
        </div>
      </div>
      <div class="col-12 xl:col-6">
        <div class="card-like">
          <h3>Ihr Liquiditätsvorteil</h3>
          <div class="result">
            {{
              ergebnis!.schenkungssteuerBetrag
                | currency: "EUR":"symbol":"1.0-0":"de-DE"
            }}
          </div>
        </div>
      </div>
    </div>

    <div style="display: flex; justify-content: flex-end">
      <button
        pButton
        label="Drucken"
        icon="icomoon-nbr-print"
        iconPos="left"
        class="p-button-secondary p-button-sm"
        onClick="window.print()"
      ></button>
    </div>

  </div>

  <hr class="separator" />

  <div>
    <h4>Gültigkeit</h4>
    <p class="disclaimer">
      Die vorliegende Berechnung gilt für das Jahr {{ gueltigkeit }}. Quelle der
      verwendeten Vervielfältiger-Daten:
      <a href="{{ gueltigkeitZusatzdaten.url }}" target="_blank"
        >{{ gueltigkeitZusatzdaten.typ }} vom
        {{ gueltigkeitZusatzdaten.datum }}</a
      >
    </p>

    <h4>Disclaimer</h4>
    <ol class="disclaimer">
      <li>
        Die sachliche Richtigkeit des Nießbrauch-Rechners wurde durch die
        {{ disclaimerPruefer }} für verschiedene Musterfälle geprüft und
        bestätigt; gleichwohl können weder die {{ disclaimerPruefer }} noch
        wir gegenüber Ihnen oder Dritten Gewähr
        für Richtigkeit, Aktualität, Vollständigkeit und Verfügbarkeit des
        ausgeführten Nießbrauch-Rechners (Rechner) übernehmen.
      </li>

      <li>
        Die durch den Rechner bereitgestellten Angaben und Rechenergebnisse
        dienen lediglich der allgemeinen Information und stellen keine
        Anlageberatung, Empfehlung oder Aufforderung zum Kauf, Verkauf, Halten
        oder Schenkung eines Finanzproduktes / Finanzinstrumentes dar. Die
        Informationen dieses Rechners sind nicht gleichbedeutend mit einer
        rechtlichen und/oder steuerlichen Beratung und können diese auch nicht
        ersetzen. Insbesondere ersetzen die in diesem Rechner bereitgestellten
        Informationen keine umfassende Beratung der persönlichen Situation des
        Verwenders, insbesondere hinsichtlich der rechtlichen oder steuerlichen
        Implikationen, des persönlichen Anlageziels sowie der jeweiligen
        Aufklärungsbedürftigkeit und Vermögenssituation. Sie stellen daher keine
        Grundlage für Anlageentscheidungen oder Schenkungen dar.
      </li>
      <li>
        Ein Nießbrauchdepot sollte nicht allein aus steuerlichen Motiven gewählt werden.
        Vielmehr gilt es, die persönliche Situation des Verwenders, insbesondere hinsichtlich
        der rechtlichen Implikationen, des persönlichen Anlageziels sowie der jeweiligen
        Aufklärungsbedürftigkeit und Vermögenssituation voll umfänglich zu erfassen und zu berücksichtigen.
        In der Ableitung ergeben sich daraus steuerliche Implikationen. Bitte nutzen Sie zur Erarbeitung
        einer Vermögensnachfolge daher unbedingt auch rechtliche und/oder steuerliche Beratung durch einen
        qualifizierten Rechtsanwalt oder Steuerberater.
      </li>
      <li>
        Der Rechner basiert auf dem Rechtsstand vom {{ disclaimerRechtsstand }}{{ gueltigkeit }}.
        Etwaige spätere Rechtsänderungen (einschließlich möglicher Anpassungen
        der Vervielfältigerwerte) sind nicht berücksichtigt.
      </li>
      <li>
        Ihre angenommene Jahresrendite wird gemäß §16 Bewertungsgesetz (BewG)
        maximal bis {{ maxZinssatzAppComponent | number: "1.2-2":"de-DE" }}%
        berücksichtigt. Sie haben die Möglichkeit, nach eigener Einschätzung und
        nach Ihrem freien Ermessen diese oder eine andere, niedrigere Rendite
        für Ihre Berechnung anzusetzen.
      </li>
      <li>Vorschenkungen bleiben in der Berechnung unberücksichtigt.</li>
      <li>
        Eine mögliche Nachversteuerung gemäß §14 II Bewertungsgesetz (BewG),
        wenn beispielsweise der Schenkende vorzeitig verstirbt, bleibt
        unberücksichtigt.
      </li>
    </ol>
  </div>
</div>
