"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TabelleSteuersaetze = exports.TabelleSteuerdaten = exports.errorMsgKeinSteuersatz = exports.errorMsgUngueltigerSteuerbetrag = exports.errorMsgKeinFreibetrag = exports.errorMsgKeineSteuerklasse = void 0;
exports.getSteuerdatenUniqueKeys = getSteuerdatenUniqueKeys;
exports.getSteuerdatenUniqueSortNr = getSteuerdatenUniqueSortNr;
exports.ermittleSteuerklasse = ermittleSteuerklasse;
exports.ermittleFreibetrag = ermittleFreibetrag;
exports.ermittleSteuersatz = ermittleSteuersatz;
exports.ermittleSteuersatzUndWertgrenzeVorhergehend = ermittleSteuersatzUndWertgrenzeVorhergehend;
/*
————————————————————————————————————————————————————————————————————————————————
Exportierte Konstanten, Typen und Arrays
————————————————————————————————————————————————————————————————————————————————
*/
// Fehlermeldungen
exports.errorMsgKeineSteuerklasse = 'Keine Steuerklasse definiert';
exports.errorMsgKeinFreibetrag = 'Kein Freibetrag definiert';
exports.errorMsgUngueltigerSteuerbetrag = 'Ungültiger Steuerbetrag (negativ)';
exports.errorMsgKeinSteuersatz = 'Kein passender Steuersatz definiert';
// Definition einer statischen Tabelle mit den Steuerklassen
// Achtung: Freiträge gelten nur bei Schenkung (nicht bei Erbschaft)!
exports.TabelleSteuerdaten = [{
  key: 'Ehepartner',
  sortNr: 0,
  label: 'Ehepartnerin/Ehepartner',
  steuerklasse: 'I',
  freibetrag: 500000
}, {
  key: 'Kinder',
  sortNr: 1,
  label: 'Tochter/Sohn',
  steuerklasse: 'I',
  freibetrag: 400000
}, {
  key: 'Enkel',
  sortNr: 2,
  label: 'Enkeltochter/Enkelsohn',
  steuerklasse: 'I',
  freibetrag: 200000
}, {
  key: 'Geschwister',
  sortNr: 3,
  label: 'Schwester/Bruder',
  steuerklasse: 'II',
  freibetrag: 20000
}, {
  key: 'NichtenNeffen',
  sortNr: 4,
  label: 'Nichte/Neffe',
  steuerklasse: 'II',
  freibetrag: 20000
}, {
  key: 'Lebensgefährte',
  sortNr: 5,
  label: 'Lebensgefährtin/Lebensgefährte',
  steuerklasse: 'III',
  freibetrag: 20000
}];
// Definition einer statischen Tabelle mit den Schenkungssteuersätzen
// 0-Einträge gegenüber Tabelle in ErbStG §19 ergänzt, damit auch für Werte unterhalb der ersten
// Stufe passende Einträge gefunden werden (bei der Suche nach der letztvorhergenden Wertgrenze
// für die Steuerberechnung mit Härteausgleichsregelung)
exports.TabelleSteuersaetze = [{
  key: {
    erwerb: 0,
    steuerklasse: 'I'
  },
  steuersatz: 0
}, {
  key: {
    erwerb: 0,
    steuerklasse: 'II'
  },
  steuersatz: 0
}, {
  key: {
    erwerb: 0,
    steuerklasse: 'III'
  },
  steuersatz: 0
}, {
  key: {
    erwerb: 75000,
    steuerklasse: 'I'
  },
  steuersatz: 7
}, {
  key: {
    erwerb: 75000,
    steuerklasse: 'II'
  },
  steuersatz: 15
}, {
  key: {
    erwerb: 75000,
    steuerklasse: 'III'
  },
  steuersatz: 30
}, {
  key: {
    erwerb: 300000,
    steuerklasse: 'I'
  },
  steuersatz: 11
}, {
  key: {
    erwerb: 300000,
    steuerklasse: 'II'
  },
  steuersatz: 20
}, {
  key: {
    erwerb: 300000,
    steuerklasse: 'III'
  },
  steuersatz: 30
}, {
  key: {
    erwerb: 600000,
    steuerklasse: 'I'
  },
  steuersatz: 15
}, {
  key: {
    erwerb: 600000,
    steuerklasse: 'II'
  },
  steuersatz: 25
}, {
  key: {
    erwerb: 600000,
    steuerklasse: 'III'
  },
  steuersatz: 30
}, {
  key: {
    erwerb: 6000000,
    steuerklasse: 'I'
  },
  steuersatz: 19
}, {
  key: {
    erwerb: 6000000,
    steuerklasse: 'II'
  },
  steuersatz: 30
}, {
  key: {
    erwerb: 6000000,
    steuerklasse: 'III'
  },
  steuersatz: 30
}, {
  key: {
    erwerb: 13000000,
    steuerklasse: 'I'
  },
  steuersatz: 23
}, {
  key: {
    erwerb: 13000000,
    steuerklasse: 'II'
  },
  steuersatz: 35
}, {
  key: {
    erwerb: 13000000,
    steuerklasse: 'III'
  },
  steuersatz: 50
}, {
  key: {
    erwerb: 26000000,
    steuerklasse: 'I'
  },
  steuersatz: 27
}, {
  key: {
    erwerb: 26000000,
    steuerklasse: 'II'
  },
  steuersatz: 40
}, {
  key: {
    erwerb: 26000000,
    steuerklasse: 'III'
  },
  steuersatz: 50
}, {
  key: {
    erwerb: Number.MAX_VALUE,
    steuerklasse: 'I'
  },
  steuersatz: 30
}, {
  key: {
    erwerb: Number.MAX_VALUE,
    steuerklasse: 'II'
  },
  steuersatz: 43
}, {
  key: {
    erwerb: Number.MAX_VALUE,
    steuerklasse: 'III'
  },
  steuersatz: 50
}];
/*
————————————————————————————————————————————————————————————————————————————————
Exportierte Funktionen
————————————————————————————————————————————————————————————————————————————————
*/
// Liste aller eindeutigen Keys aus Tabelle mit den Steuerdaten
function getSteuerdatenUniqueKeys() {
  return Array.from(new Set(exports.TabelleSteuerdaten.map(element => element.key)));
}
// Liste aller eindeutigen Sortiernummern aus Tabelle mit den Steuerdaten
function getSteuerdatenUniqueSortNr() {
  return Array.from(new Set(exports.TabelleSteuerdaten.map(element => element.sortNr)));
}
// Ermittlung Steuerklasse für gegebene Beziehung
function ermittleSteuerklasse(inputBeziehung) {
  const tabellenEintrag = exports.TabelleSteuerdaten.find(element => inputBeziehung === element.key);
  if (tabellenEintrag === undefined) {
    throw Error(exports.errorMsgKeineSteuerklasse);
  } else {
    return tabellenEintrag.steuerklasse;
  }
}
// Ermittlung Freibetrag für gegebene Beziehung
function ermittleFreibetrag(inputBeziehung) {
  const tabellenEintrag = exports.TabelleSteuerdaten.find(element => inputBeziehung === element.key);
  if (tabellenEintrag === undefined) {
    throw Error(exports.errorMsgKeinFreibetrag);
  } else {
    return tabellenEintrag.freibetrag;
  }
}
// Ermittlung Steuersatz für gegebene Kombination aus Betrag für Erwerb und Steuerklasse
function ermittleSteuersatz(inputErwerb, inputSteuerklasse) {
  // Prüfung Parameter
  if (inputErwerb < 0) {
    throw Error(exports.errorMsgUngueltigerSteuerbetrag);
  }
  // Die eigentliche Logik für den Lookup wurde der Lesbarkeit in eine separate Funktion ausgelagert
  return lookupSteuersatz({
    erwerb: inputErwerb,
    steuerklasse: inputSteuerklasse
  });
}
// Ermittlung Steuersatz für gegebene Kombination aus Betrag für Erwerb und Steuerklasse
// (für letztvorhergehende Wertgrenze)
function ermittleSteuersatzUndWertgrenzeVorhergehend(inputErwerb, inputSteuerklasse) {
  // Prüfung Parameter
  if (inputErwerb < 0) {
    throw Error(exports.errorMsgUngueltigerSteuerbetrag);
  }
  // Die eigentliche Logik für den Lookup wurde der Lesbarkeit in eine separate Funktion ausgelagert
  return lookupSteuersatzUndWertgrenzeVorhergehend({
    erwerb: inputErwerb,
    steuerklasse: inputSteuerklasse
  });
}
/*
————————————————————————————————————————————————————————————————————————————————
Interne Funktionen
————————————————————————————————————————————————————————————————————————————————
*/
// Ermittlung Steuersatz (ErbStg § 19 Abs. 1)
// Logik: Rausfiltern aller kleineren Wertgrenzen, die überschritten wurden.
//        Danach wird der (gemäß Sortierung) erste verbleibende Eintrag als der
//        passende (d.h. zu ermittelnde) verwendet.
function lookupSteuersatz(kenngroessen) {
  // Schritt 1: Filtern der Zeilen mit den Grenzbeträgen, die die Schenkungshöhe überschreiten
  // Resultat: Nur noch Einträge mit übereinstimmender Steuerklasse
  //           (Kriterium wird daher später auch nicht mehr betrachtet)
  const tabellenEintraege = exports.TabelleSteuersaetze.filter(element => {
    return kenngroessen.erwerb <= element.key.erwerb && kenngroessen.steuerklasse === element.key.steuerklasse;
  });
  // Schritt 2: Erstes Element der verbliebenden Einträge (Index 0) liefert den gesuchten Steuersatz
  // Zur Sicherheit: Tabelle wird (falls weiter oben im Rahmen der Definition versehentlich
  //                 nicht passiert) vorher nummerisch sortiert anhand der Erwerbsgrenzen.
  tabellenEintraege.sort((a, b) => {
    return a.key.erwerb - b.key.erwerb;
  });
  const tabellenEintrag = tabellenEintraege[0];
  if (tabellenEintrag === undefined) {
    throw Error(exports.errorMsgKeinSteuersatz);
  }
  return tabellenEintrag.steuersatz;
}
// Ermittlung Steuersatz sowie Wertgrenze für letztvorhergehende Wertgrenze (ErbStg § 19 Abs. 3)
// Logik: Rausfiltern aller größeren Wertgrenzen, die nicht erreicht werden.
//        Danach wird der (gemäß Sortierung) letzte verbleibende Eintrag als der
//        passende (d.h. zu ermittelnde) verwendet.
function lookupSteuersatzUndWertgrenzeVorhergehend(kenngroessen) {
  // Schritt 1: Filtern der Zeilen mit den Grenzbeträgen, die die Schenkungshöhe *nicht* überschreiten
  // Resultat: Nur noch Einträge mit übereinstimmender Steuerklasse
  //           (Kriterium wird daher später auch nicht mehr betrachtet)
  // Hinweis:  Es wird auf ">=" statt nur ">" (was einer Umkehrung des Vergleichs in der vorherigen Funktion
  //           entspräche) gefiltert, weil der "="-Fall in beiden Fällen übereinstimmt und so bei den
  //           Grenzwerten im Array "TabelleSteuersaetze" für die Suche nach der letztvorhergehenden
  //           Wertgrenze der intuitivere Wert "0" statt "-1" verwendet werden kann.
  const tabellenEintraege = exports.TabelleSteuersaetze.filter(element => {
    return kenngroessen.erwerb >= element.key.erwerb && kenngroessen.steuerklasse === element.key.steuerklasse;
  });
  // Schritt 2: Letztes Element der verbliebenden Einträge liefert den gesuchten Steuersatz,
  //            durch vorherige *absteigende* nummerische Sortierung anhand der Erwerbsgrenzen
  //            wird aus dem oben erwähnten letzten Element das *erste* Element (Index 0)
  tabellenEintraege.sort((a, b) => {
    return b.key.erwerb - a.key.erwerb;
  });
  const tabellenEintrag = tabellenEintraege[0];
  if (tabellenEintrag === undefined) {
    throw Error(exports.errorMsgKeinSteuersatz);
  }
  return {
    steuersatz: tabellenEintrag.steuersatz,
    wertgrenze: tabellenEintrag.key.erwerb
  };
}
